import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FIELD_NAME_PROFILES } from 'types/user';
import { useModalContext } from 'context/modal/modalContext';
import { activeTab } from 'components/navBar/navBar';

interface PaymentsTableProps {
  translation: string;
  rowsPayOut: any[];
  rowsPayIn: any[];
}

export default function PaymentsTable({ translation, rowsPayOut, rowsPayIn }: PaymentsTableProps) {
  const { t } = useTranslation(translation);
  const { t: depositT } = useTranslation('deposit');
  const { openModalWithData } = useModalContext();

  const renderCell = () => {
    return (
      <>
        <TableCell>{t('method')}</TableCell>
        <TableCell align="right">{t('type')}</TableCell>
        <TableCell align="right">{t('commission')}</TableCell>
        <TableCell align="right">{t('processing')}</TableCell>
        <TableCell align="right">{t('limit')}</TableCell>
        <TableCell align="right"></TableCell>
      </>
    );
  };

  const handleOpenDeposit = () => {
    openModalWithData(FIELD_NAME_PROFILES.DEPOSIT, { activeTab: activeTab['deposit'] });
  };

  const handleOpenWithDraw = () => {
    openModalWithData(FIELD_NAME_PROFILES.DEPOSIT, { activeTab: activeTab['withDraw'] });
  };

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', mb: 2 }}>
        <Typography align="center" variant="h6">
          {depositT('deposit')}
        </Typography>
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>{renderCell()}</TableRow>
          </TableHead>
          <TableBody>
            {rowsPayIn.length
              ? rowsPayIn?.map((row: any) => (
                  <TableRow
                    key={row.name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <img src={row.image} width={100} />
                    </TableCell>
                    <TableCell align="right">{row.name}</TableCell>
                    <TableCell align="right">{t('free')}</TableCell>
                    <TableCell align="right">{t('time')}</TableCell>

                    <TableCell align="right">
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                          {depositT('min')} {row.sitePayInMethods[0].minValue}{' '}
                          {row.sitePayInMethods[0].currencyCode}
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                          {depositT('max')} {row.sitePayInMethods[0].maxValue}{' '}
                          {row.sitePayInMethods[0].currencyCode}
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        sx={{ ml: 2, minWidth: '162px' }}
                        variant="contained"
                        onClick={handleOpenDeposit}
                      >
                        {depositT('deposit')}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', mt: 2, mb: 2 }}>
        <Typography align="center" variant="h6">
          {depositT('withdrawFunds')}
        </Typography>
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>{renderCell()}</TableRow>
          </TableHead>
          <TableBody>
            {rowsPayOut.length
              ? rowsPayOut?.map((row: any) => (
                  <TableRow
                    key={row.name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <img src={row.image} width={100} />
                    </TableCell>
                    <TableCell align="right">{row.name}</TableCell>
                    <TableCell align="right">{t('free')}</TableCell>
                    <TableCell align="right">{t('time')}</TableCell>

                    <TableCell align="right">
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                          {depositT('min')} {row.sitePayOutMethods[0].minValue}{' '}
                          {row.sitePayOutMethods[0].currencyCode}
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                          {depositT('max')} {row.sitePayOutMethods[0].maxValue}{' '}
                          {row.sitePayOutMethods[0].currencyCode}
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell align="right">
                      <Button sx={{ ml: 2, minWidth: '110px' }} variant="contained" onClick={handleOpenWithDraw}> 
                        {depositT('withdrawFunds')}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
