// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Navigation, Pagination, Autoplay, A11y } from 'swiper/modules';
import { Box } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import x1 from 'assets/banners/x1.png';
import x2 from 'assets/banners/x2.png';
import x01 from 'assets/banners/x01.png';
import x02 from 'assets/banners/x02.png';
import x001 from 'assets/banners/x001.png';
import x002 from 'assets/banners/x002.png';
import x0001 from 'assets/banners/x0001.png';
import x0002 from 'assets/banners/x0002.png';
import { Bonuses, Deposit, Favorites, Games } from 'assets/icons/menuIcons/index';
import { useEffect, useState } from 'react';
import { ISelectedBanner } from 'components/sections/topSection';
import styles from './carouselVertical.module.scss';

export const carouselItems = [
  {
    id: '1',
    itemName: '',
    icon: Games,
    bannerImage: [x1, x2],
    titleText: ['Cамые популярные игры', 'Высокая отдача в ретро играх'],
    secondaryText: [''],
    button: [
      {
        buttonColor: 'red',
        text: 'Популярные игры',
        link: '/statistics',
        routeData: { activeTab: '0' },
      },
      {
        buttonColor: 'red',
        text: 'Играть',
        link: '/retro',
      },
    ],
  },
  {
    id: '2',
    itemName: 'deposit',
    icon: Deposit,
    bannerImage: [x01, x02],
    titleText: ['Круглосуточная связь ', 'Персональные менеджер'],
    secondaryText: ['с нашими сотрудниками в телеграмм'],

    button: [
      {
        type: 'external',
        buttonColor: 'red',
        text: 'Перейти в Телеграмм',
        link: 'https://web.telegram.org/',
      },
      {
        type: 'tooltip',
        buttonColor: 'orange',
        text: 'Связаться с менеджером',
        links: [
          { name: 'FB менеджер', link: 'https://www.facebook.com/' },
          { name: 'VK менеджер', link: 'https://vk.com/' },
          { name: 'Е-майл', link: 'mailto:someone@example.com' },
          { name: 'Телеграмм', link: 'https://web.telegram.org/' },
        ],
      },
    ],
  },
  {
    id: '3',
    itemName: 'bonuses',
    titleText: ['', 'Мульти-промокоды и розыгрыши в Телеграмме'],
    icon: Bonuses,
    bannerImage: [x001, x002],
    secondaryText: [
      [
        'Бонусы каждый день на нашем телеграмм канале',
        'Подтвердите соц. сети и получите Бонус ',
        'Колесо фортуны каждый день до 1000 руб',
        'Индивидуальные бонусы от персонального менеджера',
      ],
    ],

    button: [
      {
        buttonColor: 'green',
        text: 'Бонусы',
        link: '/bonuses',
      },
      {
        buttonColor: 'red',
        text: 'Присоединиться',
        link: '',
      },
    ],
  },
  {
    id: '4',
    itemName: 'favorite',
    icon: Favorites,
    bannerImage: [x0001, x0002],
    titleText: ['Вы можете пополнить счет через наш Телеграмм', 'Вывод средств до 60 минут'],
    secondaryText: [''],
    button: [
      {
        buttonColor: 'red',
        text: 'Телеграмм',
        link: '',
      },
      {
        type: 'openModal',
        modal: { modalName: 'deposit', activeTab: '2' },
        isSensitive: true,
        buttonColor: 'green',
        text: 'Вывод',
        link: '',
      },
    ],
  },
];

interface CarouselVertical {
  isCategorypage?: boolean;
  onChange?: () => void;
  lineStatus: string;
}

export function CarouselVertical({ onChange, lineStatus }: CarouselVerticalProps) {
  const [selectedItem, setSelectedItem] = useState<ISelectedBanner>(carouselItems[0]);

  useEffect(() => {
    onChange?.(selectedItem);
  }, [selectedItem]);

  return (
    <Box className={styles.main_container}>
      <Swiper
        modules={[Autoplay, Navigation, Pagination, A11y]}
        direction={lineStatus != 'horizontal' ? 'vertical' : 'horizontal'}
        slidesPerView={4}
        spaceBetween={lineStatus != 'horizontal' ? -64 : 0}
        navigation
        scrollbar={{ draggable: false }}
        className={styles.sliderWrapperVertical}
      >
        {carouselItems.map(
          ({ icon, itemName, bannerImage, titleText, secondaryText, button }, index) => {
            return (
              <SwiperSlide key={index}>
                <img
                  src={icon}
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    setSelectedItem((curr) => ({
                      ...curr,
                      itemName,
                      bannerImage,
                      titleText,
                      secondaryText,
                      button,
                    }))
                  }
                />
              </SwiperSlide>
            );
          },
        )}
      </Swiper>
    </Box>
  );
}
