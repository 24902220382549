import Card from '@mui/material/Card';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import cx from 'classnames';
import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { routing } from 'constants/routing';
import { useAuthContext } from 'context/auth/authContext';
import { useModalContext } from 'context/modal/modalContext';
import { FIELD_NAME } from 'types/login';
import noImage from 'assets/games/noImage.png';
import { useTranslation } from 'react-i18next';
import styles from './winnersCard.module.scss';
import { MODAL_TYPES } from 'types/modals';

interface WinnersCardProps {
  /**TODO: write real types after getting all final data from BE */
  data: any;
  className?: string;
}

export default function WinnersCard({ data, className }: WinnersCardProps) {
  const { gameId, providerId, image, name, isLiked, isUnliked } = data;
  const { t } = useTranslation('common');
  const { user } = useAuthContext();
  const { openModalWithData } = useModalContext();
  const navigate = useNavigate();

  const handlePlayGame = () => {
    if (user) {
      openModalWithData(MODAL_TYPES.CHOOSE_BALANCE, {
        gameId,
        image,
        name,
        isLiked,
        isUnliked,
        providerId,
      });
    } else {
      openModalWithData(FIELD_NAME.LOGIN_FOR_PLAY_GAME, {
        gameId: gameId,
        image,
        name,
        providerId,
      });
    }
  };

  const handleDemoGame = () => {
    navigate(`${routing.gameDemo}`, {
      state: {
        gameId: gameId,
        providerId: providerId,
        image: image,
      },
    });
  };

  return (
    <>
      <Box className={cx(styles.box_card, className)}>
        <Card className={styles.card}>
          <Box className={styles.img}>
            <img
              style={{ maxWidth: '126px' }}
              src={image}
              alt="game"
              onError={({ currentTarget }: any) => (currentTarget.src = noImage)}
            />
          </Box>
        </Card>
        <Box className={styles.card_content}>
          <Box className={styles.action}>
            <Button className={styles.play} onClick={handlePlayGame}>
              <PlayCircleIcon />
            </Button>
            <Button className={styles.demo} onClick={handleDemoGame}>
              {t('demo')}{' '}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}
