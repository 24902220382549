import { useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useUserData } from 'hooks/user/useUserData';
import { useTranslation } from 'react-i18next';
import DescriptionIcon from '@mui/icons-material/Description';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LoaderWithBg from 'components/loader/loader';
import styles from './bonuses.module.scss';

function Bonuses() {
  const { getUserBonuses, bonusData, isLoading } = useUserData();
  const { t } = useTranslation('deposit');
  const { t: commonT } = useTranslation('common');

  useEffect(() => {
    getUserBonuses();
  }, []);

  return (
    <Box className={styles.main_area}>
      <Box className={styles.main_title}>{t('bonus')}</Box>

      {!isLoading ? (
        <Box className={styles.bonuses_area}>
          {bonusData.length ? (
            bonusData.map((elm: any, i: number) => {
              return (
                <Box key={i} className={styles.bonus_info}>
                  <Box className={styles.bonus_title_area}>
                    <Box>
                      <Typography variant={'h4'}>{elm.name}</Typography>
                      {elm.isActive && (
                        <CheckCircleIcon sx={{ color: 'green', fontSize: '18px' }} />
                      )}
                      <Typography
                        component={'span'}
                        fontSize={'14px'}
                        color={elm.isActive ? 'green' : 'red'}
                      >
                        {elm.isActive && 'Active'}
                      </Typography>
                    </Box>
                    <Typography component={'span'} fontSize={'11px'}>
                      {elm.comment}
                    </Typography>
                  </Box>
                  <Typography component={'span'}>
                    {commonT('amount')}: {elm.amount} × {elm.weiger}
                  </Typography>
                  <Box className={styles.button_area}>
                    <Button
                      sx={{ backgroundColor: elm.isActive ? 'gray' : 'green' }}
                      disabled={elm.isActive}
                    >
                      {commonT('apply')}
                    </Button>
                    <Button sx={{ backgroundColor: 'red' }}>{commonT('refuse')}</Button>
                  </Box>
                </Box>
              );
            })
          ) : (
            <Box className={styles.haveNoBonus_area}>
              <Box className={styles.haveNoBonus_box}>
                <DescriptionIcon />
                <Typography fontSize={'12px'}>{t('haveNoBonuses')}</Typography>
              </Box>
            </Box>
          )}
        </Box>
      ) : (
        <Box sx={{ minHeight: '600px' }}>
          <LoaderWithBg isOpen={isLoading} />
        </Box>
      )}
    </Box>
  );
}

export default Bonuses;
