import { Box, CircularProgress, Stack, Tab, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import DescriptionIcon from '@mui/icons-material/Description';
import viza from 'assets/payment/visa.png';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { ITabs } from 'components/modals/depositModal/depositModal';
import { useTransaction } from 'hooks/transactions/useTransaction';
import styles from './history.module.scss';

enum HISTORY_ITEM {
  DEPOSIT = 'deposit',
  WITHDRAW = 'withdraw',
  BONUS = 'bonus',
}

interface HistoryProps {
  historyTabMenu: ITabs[];
}

function History({ historyTabMenu }: HistoryProps) {
  const { t } = useTranslation('deposit');
  const [value, setValue] = useState<string>('0');
  const [page] = useState(0);

  const { historyList, isLoading, getAllDepositTransactions, getAllWithdrawTransactions } =
    useTransaction();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue + '');
  };

  useEffect(() => {
    if (historyTabMenu.length) {
      const paramsQuery = `&page=${page + 1}`;
      let res;
      const fetchData = async (type: string) => {
        switch (type) {
          case HISTORY_ITEM.BONUS:
            res = await getAllDepositTransactions(paramsQuery + '&isBonus=true');
            break;
          case HISTORY_ITEM.DEPOSIT:
            res = await getAllDepositTransactions(paramsQuery + '&isBonus=false');
            break;
          case HISTORY_ITEM.WITHDRAW:
            res = await getAllWithdrawTransactions(paramsQuery);
            break;
          default:
            null;
        }
      };

      fetchData(historyTabMenu[+value].name);
    }
  }, [historyTabMenu, value]);

  return (
    <Box className={styles.main_area}>
      <Box className={styles.main_title}>{t('history')}</Box>
      <Box className={styles.methods_block}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile>
              {historyTabMenu.map(({ name }, index) => {
                return (
                  <Tab
                    key={index}
                    value={index + ''}
                    label={t(name)}
                    sx={{ alignItems: 'start' }}
                  />
                );
              })}
            </TabList>
          </Box>
          <TabPanel value={value}>
            <Box className={styles.history_block}>
              {isLoading ? (
                <Stack
                  sx={{ minHeight: '520px', color: 'grey.500', justifyContent: 'center' }}
                  spacing={2}
                  direction="row"
                >
                  <CircularProgress color="warning" />
                </Stack>
              ) : (
                <Box className={styles.history_block_lists}>
                  {historyList.length ? (
                    historyList.map((item: any, index: number) => {
                      return (
                        <Box key={index} className={styles.history_item}>
                          <Box>
                            <img src={item.icon ?? viza} />
                          </Box>
                          <Box>
                            {item.amount} {item.currency}
                          </Box>
                          <Box> {`${moment(item.date).format('DD/MM/Y HH:mm:ss')}`} </Box>
                          <Box
                            //TODO: write css detector function
                            className={cx({
                              [styles.pending]: item.status === 'pending',
                              [styles.completed]: item.status === 'completed',
                              [styles.failed]: item.status === 'failed',
                              [styles.returned]: item.status === 'canceled_returned',
                              [styles.canceled]: item.status === 'canceled',
                            })}
                          >
                            {t(item.status)}
                          </Box>
                        </Box>
                      );
                    })
                  ) : (
                    <Box className={styles.empty}>
                      <DescriptionIcon />
                      <Typography component="span">{t('emptyHistory')}</Typography>
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </TabPanel>
        </TabContext>
      </Box>
    </Box>
  );
}

export default History;
