import { useEffect, useState } from 'react';
import TermsService from 'services/terms.service';

export const useTerms = () => {
  const [terms, setTerms] = useState([]);
  // const [isLoading, setIsLoading] = useState(true);
  // const [error, setError] = useState(null);

  const getAllTerms: any = async () => {
    try {
      const {
        data: { data },
      } = await TermsService.getSiteTerms();
      setTerms(data);
    } catch (error: any) {
      console.log('LOG____>', error);
    }
  };

  useEffect(() => {
    getAllTerms();
  }, []);

  return {
    // isLoading,
    // error,
    getAllTerms,
    terms,
  };
};
