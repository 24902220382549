import { Box, IconButton, Paper, Typography } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import GameCard from 'components/gameCard/gameCard';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import color from 'styles/colors.module.scss';
import SelectCategory from 'components/select/select';
import { useCategory } from 'hooks/categoryGames/useCategory';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from 'context/auth/authContext';
import { useSelector } from 'react-redux';
import { IGame } from 'types/game';
import LoaderWithBg from 'components/loader/loader';
import { useSubProviderGames } from 'hooks/subProviderGames/useSubProviderGames';
import styles from './category.module.scss';

const useStyles = makeStyles(() => ({
  ul: {
    '& .MuiPaginationItem-root.Mui-selected, & .MuiPaginationItem-root.Mui-selected:hover': {
      color: color.mainOrange,
      backgroundColor: 'unset',
    },
  },
  paper: {
    height: '40px',
    border: 'thin solid white',
    backgroundColor: 'unset!important',
    '& svg': {
      fill: 'white',
    },
  },
}));

export const CategoryPage = () => {
  const params = useParams();
  const { user } = useAuthContext();

  const { device } = useSelector<any>((state) => state.device) as {
    device: string;
  };
  const { t } = useTranslation('common');
  const { categories } = useSelector<any>((state) => state.categories) as {
    categories: any;
  };

  const { isLoading } = useCategory(!categories.length, user, device);
  const [games, setGames] = useState<IGame[]>([]);
  const [page, setPage] = useState(1);
  const [selectedCategoryId, setSelectedCategoryId] = useState('');
  const [gameName, setGameName] = useState<string | null>(null)
  const [debouncedValue, setDebouncedValue] = useState('');
  const { getAllSubProviderGames, totalPages, isLoading: gamesIsLoading } = useSubProviderGames();

  const classes = useStyles();

  const avtiveCategory = useMemo(() => {
    const activeId = selectedCategoryId ? selectedCategoryId : params?.id;
    return activeId ? categories.filter((el: any) => el.id === +activeId)?.[0] : [];
  }, [params, selectedCategoryId, categories]);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const handleOnSelect = (value: string) => {
    setPage(1)
    setGameName('')
    setSelectedCategoryId(value);
  };

  const handleGameSearch = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value } = e.target
    setGameName(value)
  }

  useEffect(() => {
    setPage(1);

    const delay = 500;
    const timeoutId = setTimeout(() => {
      if (typeof gameName === 'string') {
        setDebouncedValue(gameName);
      } else {
        setDebouncedValue('');
      }
    }, delay);

    return () => clearTimeout(timeoutId);
  }, [gameName]);

  useEffect(() => {
    if (avtiveCategory) {
      let query = `page=${page}&categoryId=${avtiveCategory.id}`
      debouncedValue ? query += `&name=${debouncedValue}` : query

      const forPublic = !user ? '/public?' : undefined;
      const getGames = async () => {
        const result = await getAllSubProviderGames(query, forPublic);

        if (result?.status === 200) {
          const { data: {
            games
          } } = result;
          setGames(games);
        }
      };

      getGames();
    }

  }, [page, debouncedValue, avtiveCategory]);

  return (
    <>
      <Box sx={{ width: '100%', display: 'flex' }}>
        <Box className={styles.games_container}>
          <Box className={styles.top}>
            <Box
              sx={{
                width: '80%',
                maxWidth: '1024px',
                mt: 4,
                ml: 4,
                mr: 4,
                display: { xs: 'none', md: 'flex' },
              }}
            >
              <Paper
                className={classes.paper}
                component="form"
                sx={{ display: 'flex', alignItems: 'center', width: '100%' }}
              >
                <InputBase
                  size="small"
                  fullWidth
                  sx={{ ml: 1, flex: 1 }}
                  placeholder={t('searchGame')}
                  value={gameName ?? ''}
                  inputProps={{ 'aria-label': 'search game' }}
                  onChange={(e) => handleGameSearch(e)}
                />
                <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                  <SearchIcon />
                </IconButton>
              </Paper>
            </Box>
            <Box sx={{ mt: 4, mr: 4, ml: 4 }}>
              <SelectCategory
                initValue={avtiveCategory?.id ? avtiveCategory?.id + '' : undefined}
                width={200}
                categoryList={categories}
                onSelect={handleOnSelect}
              />
            </Box>
          </Box>
          <Box className={styles.category_Title}>
            <Typography align="left">{avtiveCategory?.title}</Typography>
          </Box>
          {isLoading || gamesIsLoading ? (
            <LoaderWithBg isOpen={isLoading || gamesIsLoading || false} />
          ) : (
            <Box className={styles.games}>
              {games?.map(
                ({ image, id, providerId, isFavorite, isUnliked }: any, index: number) => {
                  return (
                    <GameCard
                      key={index}
                      imageUrl={image}
                      gameId={id}
                      className={styles.gameItem}
                      providerId={providerId}
                      isFavorite={isFavorite}
                      isUnliked={isUnliked}
                    />
                  );
                },
              )}
            </Box>
          )}
          {(!isLoading && !gamesIsLoading) ? (
            <Stack spacing={2} sx={{ m: 4 }}>
              <Pagination
                count={totalPages}
                className={classes.ul}
                hideNextButton
                hidePrevButton
                page={page}
                onChange={handleChange}
              />
            </Stack>
          ) : null}
        </Box>
      </Box>
    </>
  );
};
