import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { routing } from 'constants/routing';
import { useGame } from 'hooks/playGames/useGame';
import { ModalState } from 'context/modal/modalContext';
import { useUser } from 'hooks/user/useUser';
import { dialog } from 'constants/common';
import { useTranslation } from 'react-i18next';
import styles from './logout.module.scss';

interface GameSessionProps {
  onClose: () => void;
  props?: ModalState;
}

export const Logout = ({ onClose, props }: GameSessionProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation('common');
  const { closeGame } = useGame();
  const { removeUser } = useUser();

  const handleCancel = async () => {
    onClose();
  };

  const handleExit = async () => {
    if (props?.data.gameId !== null) {
      const res = await closeGame(props?.data.gameId);
      if (res?.status === 200) {
        removeUser();
        onClose();
        navigate(`${routing.home}`);
      }
    } else {
      removeUser();
      onClose();
      navigate(`${routing.home}`);
    }
  };

  return (
    <Box className={styles.content}>
      <Box>
        <Typography sx={{ mb: 2 }} align="center">
          {t(dialog.WANT_EXIT)}
        </Typography>
      </Box>
      <Box className={styles.action}>
        <Button
          size="large"
          type="submit"
          fullWidth
          className={styles.close_btn}
          onClick={handleCancel}
        >
          {t('cancel')}
        </Button>
        <Button
          size="large"
          type="submit"
          fullWidth
          className={styles.playing_btn}
          onClick={handleExit}
        >
          {t('yes')}
        </Button>
      </Box>
    </Box>
  );
};
