import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './verificationImagesUpload.module.scss';

interface VerificationImagesUploadProps {
  title: string;
  text: string;
  keys: string;
  image?: string;
  status: null | number;
  onImageUpload: (url: any, keys: string) => void;
}

const initialData = { imageUrl: '', imageName: '' };

function VerificationImagesUpload({
  onImageUpload,
  title,
  image,
  text,
  status,
  keys,
}: VerificationImagesUploadProps) {
  const { t: profileT } = useTranslation('profile');
  const [errorMsg, setErrorMsg] = useState('');
  const [imageData, setImageData] = useState<any>(initialData);

  const validateSelectedFile = (selectedFile: any) => {
    const MAX_FILE_SIZE = 5120; // 5MB

    if (!selectedFile) {
      setErrorMsg('erorrrr');
      return;
    }

    const fileSizeKiloBytes = selectedFile.size / 1024;
    if (selectedFile.type !== 'image/png' && selectedFile.type !== 'image/jpeg') {
      setErrorMsg('fileDoesNotSupport');
      return;
    }

    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      setErrorMsg('fileSizeIsGreaterThanMax');
      return;
    }
    setErrorMsg('');
    return true;
  };

  const handleFileUpload = (event: any) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      if (reader) {
        if (validateSelectedFile(file)) {
          onImageUpload?.(reader.result, keys);
          setImageData({ ...imageData, imageUrl: reader?.result, imageName: file.name });
        }
      }
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    if (status === 200) {
      setImageData(initialData);
    }
  }, [status]);

  return (
    <Box className={styles.mainArea} sx={{ m: 2 }}>
      <Typography variant="h1" fontSize={14} textAlign={'left'}>
        {profileT(title)}
      </Typography>
      {
        image ? <img src={image} alt="Selfy" /> : null
      }
      <Typography fontSize={12}>{profileT(text)}</Typography>
      <Box className={styles.imageUpload}>
        <Typography component={'span'} style={{ fontSize: '16px' }}>
          {profileT('dropFilesHere')}{' '}
          <label htmlFor={`upload-image-${keys}`}>
            <Typography component={'span'} className={styles.spanWithColor}>
              {profileT('browse')}
            </Typography>
            <input
              id={`upload-image-${keys}`}
              accept="image/*"
              hidden
              type="file"
              onChange={handleFileUpload}
            />
          </label>
        </Typography>
      </Box>
      <Box className={styles.imageUploadForMobile}>
        <label htmlFor={`upload-image-${keys}`}>
          <Typography component={'span'} fontSize={14}>
            {profileT('browseFile')}
          </Typography>
          <input
            id={`upload-image-${keys}`}
            accept="image/*"
            hidden
            type="file"
            onChange={handleFileUpload}
          />
        </label>
        <Typography fontSize={12} mt={1}>
          {profileT('fileFormatInfo')}
        </Typography>
      </Box>
      {errorMsg !== '' ? (
        <Typography component="span" color={'red'}>
          {profileT(errorMsg)}
        </Typography>
      ) : null}
      {imageData.imageUrl !== '' && (
        <Box className={styles.imageView}>
          <img src={imageData.imageUrl} />
          <Typography component="span" fontSize={14}>
            {imageData.imageName}
          </Typography>
        </Box>
      )}
    </Box>
  );
}

export default VerificationImagesUpload;
