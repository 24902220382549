import { Box } from '@mui/material';
import magnit from './image/Group 1@2x.png';
import './logoAnim.module.scss';
import styles from './logoAnim.module.scss';

function LogoAnim() {
  return (
    <Box className={styles.logoAnimMain}>
      <Box>
        <img src={magnit} alt="" width={120} />
      </Box>
      <Box className={styles.animationX}>
        <svg width={550}>
          <symbol id="s-text">
            <text textAnchor="middle" x="50%" y="50%" dy=".35em">
              X
            </text>
          </symbol>
          <use className={styles.text} xlinkHref="#s-text"></use>
          <use className={styles.text} xlinkHref="#s-text"></use>
          <use className={styles.text} xlinkHref="#s-text"></use>
          <use className={styles.text} xlinkHref="#s-text"></use>
          <use className={styles.text} xlinkHref="#s-text"></use>
        </svg>
      </Box>
    </Box>
  );
}

export default LogoAnim;
