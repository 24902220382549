import { Box, Button, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { routing } from 'constants/routing';
import { useGame } from 'hooks/playGames/useGame';
import noImage from 'assets/games/noImage.png';
import { ModalState } from 'context/modal/modalContext';
import { useTranslation } from 'react-i18next';
import styles from './gameSession.module.scss';

interface GameSessionProps {
  onClose: () => void;
  props?: ModalState;
}

export const GameSession = ({ onClose, props }: GameSessionProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation('common');
  const { state } = useLocation();
  const { closeGame, continueGame } = useGame();

  const handleCloseGame = async () => {
    const res = await closeGame(props?.data.sessionId);
    if (res?.status === 200) {
      onClose();
      if (props?.isOpenGame) {
        navigate(`${routing.game}`, {
          state: { ...state },
        });
      }
    }
  };

  const handleContinuePlaying = async () => {
    const res = await continueGame();
    if (res?.status === 200) {
      const { data } = res;
      navigate(`${routing.game}`, {
        state: {
          launchURL: data.launchURL,
          sessionId: data.id,
          gameCounts: data.gameCounts,
          gameId: data.gameId,
          isLiked: data.isFavorite,
          isUnliked: data.isUnliked,
        },
      });
      onClose();
    }
  };

  return (
    <Box>
      <Typography sx={{ mb: 2 }} align="center" variant="h6">
        {t('gameisOpened')}
      </Typography>
      <Box sx={{ m: 2, display: 'flex', alignItems: 'flex-start' }}>
        <img
          width="120"
          src={props?.data.image}
          onError={({ currentTarget }) => (currentTarget.src = noImage)}
        />
        <Typography sx={{ margin: '4px 6px', fontSize: '14px' }} align="right">
          {props?.data.name}
        </Typography>
      </Box>
      <Box className={styles.action}>
        <Button
          size="large"
          type="submit"
          fullWidth
          className={styles.close_btn}
          onClick={handleCloseGame}
          sx={{ minWidth: '180px' }}
        >
          {t('closeGame')}
        </Button>
        <Button
          size="large"
          type="submit"
          fullWidth
          className={styles.playing_btn}
          onClick={handleContinuePlaying}
        >
          {t('continueGame')}
        </Button>
      </Box>
    </Box>
  );
};
