import http2 from '../http2-common';

class TransactionDataService {

  getAllPayInMethods = (headers, type) => {
    return http2.get(`/finances/pay-in/methods/all?${type}`, { headers });
  };

  getAllPayOutMethods = (headers, type) => {
    return http2.get(`/finances/pay-out/methods/all?${type}`, { headers });
  };

  getAllDepositTransactions = (headers, query) => {
    return http2.get(`/finances/pay-in/transactions?sortDir=DESC&pageSize=20${query}`, { headers });
  };

  getAllWithdrawTransactions = (headers, query) => {
    return http2.get(`/finances/pay-out/transactions?sortDir=DESC&pageSize=20${query}`, { headers });
  };
  
}

const TransactionService = new TransactionDataService();

export default TransactionService;
