import { useApiErrors } from 'hooks/errors/useApiErrors';
import { useStorage } from 'hooks/authHooks/useStorage';
import { useEffect, useState } from 'react';
import { generateHeaders } from 'utils/utils';
import { useUser } from 'hooks/user/useUser';
import { useDispatch } from 'react-redux';
import ProviderService from 'services/provider.service';
import { setProviders, setProvidersWithoutGames } from 'redux/actions/providerActions';

export const useProviders = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState('');
  const { userToken } = useStorage();
  const { removeUser } = useUser();
  const { errorHandler } = useApiErrors();
  const [providersListWithoutGames, setProvidersListWithoutGames] = useState([]);
  const dispatch = useDispatch();
  const initHeaders = generateHeaders(userToken());

  const geAlltProviders: any = async (headers = initHeaders) => {
    try {
      setIsLoading(true);

      const {
        data: { data },
      } = await ProviderService.getAllProviders(headers);
      setIsLoading(false);
      if (Array.isArray(data)) {
        dispatch(setProviders(data));
      }
    } catch (error: any) {
      if (error.response) {
        const {
          response: {
            data: { message, status },
          },
        } = error;

        const result = await errorHandler(status, message);
        const { newHeader, needLogout } = result ?? {};
        if (needLogout) {
          removeUser();
        } else if (!needLogout && newHeader) {
          return await geAlltProviders(newHeader);
        } else {
          console.log('LOG____>', message);
          setError(message);
        }
      }
    }
  };

  const getAllProvidesWithoutGames: any = async (headers = initHeaders) => {
    try {
      setIsLoading(true);

      const {
        data: { data },
      } = await ProviderService.getAllProvidersWithoutGames(headers);
      setIsLoading(false);
      setProvidersListWithoutGames(data);
      dispatch(setProvidersWithoutGames(data));
    } catch (error: any) {
      if (error.response) {
        const {
          response: {
            data: { message, status },
          },
        } = error;

        const result = await errorHandler(status, message);
        const { newHeader, needLogout } = result ?? {};
        if (needLogout) {
          removeUser();
        } else if (!needLogout && newHeader) {
          return await getAllProvidesWithoutGames(newHeader);
        } else {
          console.log('LOG____>', message);
          setError(message);
        }
      }
    }
  };

  useEffect(() => {
    geAlltProviders();
  }, []);

  return {
    isLoading,
    getAllProvidesWithoutGames,
    providersListWithoutGames,
    error,
  };
};
