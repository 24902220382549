import { actionTypes } from 'redux/constants/actionTypes';

export const setProviders = (providers) => {
  return {
    type: actionTypes.SET_PROVIDERS,
    payload: providers,
  };
};

export const setProvidersWithoutGames = (providers) => {
  return {
    type: actionTypes.SET_PROVIDERS_WITHOUT_GAMES,
    payload: providers
  }
}
