import { Box, LinearProgress, LinearProgressProps, Typography } from '@mui/material';
import cx from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styles from './level.module.scss';

type variantTypes = 'determinate' | 'indeterminate' | 'buffer' | 'query' | undefined;

interface ILevel {
  image: string;
  name: string;
  cashbackPercent: string;
}

interface LevelProps {
  className?: string;
  variant?: variantTypes;
}
function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35, textAlign: 'center' }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const Level = ({ className, variant }: LevelProps) => {
  const { t } = useTranslation('common');
  const [currentLevel, setCurrentLevel] = useState<ILevel>();
  const [range, setRange] = useState({ from: '', to: '' });
  const userData = useSelector<any>((state) => state.userData) as any;
  const { levelGroups } = useSelector<any>((state) => state.siteConfigs) as { [x: string]: any };
  const [progress, setProgress] = useState(0);
  const [buffer, setBuffer] = useState(0);

  useEffect(() => {
    if (userData?.levelGroup && levelGroups?.length) {
      const progressPerent = userData.levelGroup.levelPercent;
      const currentLevel = levelGroups?.filter((el: any) => el.id === userData.levelGroup.id)?.[0];
      const index = levelGroups?.findIndex((el: any) => el.id === userData.levelGroup.id);
      const from = currentLevel?.name;
      const to = levelGroups[index + 1] ? levelGroups[index + 1]?.name : levelGroups[index]?.name;
      setRange((range) => ({ ...range, from, to }));
      setCurrentLevel(currentLevel);
      if (progressPerent > 0) {
        setProgress(progressPerent);
        setBuffer(progressPerent + 5);
      }
    }
  }, [userData, levelGroups]);

  return (
    <Box
      className={cx(styles.menu_header, className)}
      style={{ backgroundImage: `url("${currentLevel?.image}")` }}
    >
      <Box className={styles.title}>
        <Typography textAlign="left">
          {t('currentLevel')} - {currentLevel?.name}
        </Typography>
        <Typography textAlign="left">
          {`${currentLevel?.cashbackPercent}%`} Кэшбэк
        </Typography>
      </Box>
      <Box className={styles.progress_bar}>
        <LinearProgressWithLabel variant={variant} value={progress} valueBuffer={buffer} />
      </Box>
      <Box className={styles.progress_label}>
        <Box>{range.from}</Box> <Box>{range.to}</Box>
      </Box>
    </Box>
  );
};

export default Level;
