import { Box, IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import language from 'assets/icons/language.svg';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useStorage } from 'hooks/authHooks/useStorage';
import styles from './languaMenu.module.scss';
import { useTranslation } from 'react-i18next';

interface LanguageMenuProps {
  isMobile?: boolean;
}

export default function LanguageMenu({ isMobile }: LanguageMenuProps) {
  const { t, i18n } = useTranslation();
  const { setStorageData, readStorageData } = useStorage();

  const [activeLang, setActiveLang] = useState(readStorageData('siteLanguage'));
  const [anchorElLang, setAnchorElLang] = useState<null | HTMLElement>(null);
  const { languages } = useSelector<any>((state) => state.siteConfigs) as { [x: string]: any };

  const handleCloseLangMenu = (code: string | null) => {
    setAnchorElLang(null);
    if (code) {
      i18n.changeLanguage(code);
      setActiveLang(code);
      setStorageData({ siteLanguage: code });
    }
  };

  const handleOpenLangMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    if (languages?.length > 0) {
      setAnchorElLang(event.currentTarget);
    }
  };

  return (
    <>
      <Box className={styles.main} sx={{ mt: isMobile ? 3 : 0 }}>
        <Tooltip title={t('common:changeLanguage')}>
          <IconButton onClick={handleOpenLangMenu} sx={{ p: 0, ml: isMobile ? 0 : 3 }}>
            <img src={language} alt="languageIcon" width={28} height={28} />
            <Typography className={styles.lang_text}>{activeLang ?? languages?.[0]?.code}</Typography>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        sx={{ mt: '45px' }}
        id="menu-appbar"
        anchorEl={anchorElLang}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 50,
        }}
        open={Boolean(anchorElLang)}
        onClose={() => handleCloseLangMenu(null)}
        slotProps={{
          paper: {
            sx: {
              ul: { display: isMobile ? 'flex' : 'unset' },
            },
          },
        }}
      >
        {languages?.map((item: any) => (
          <MenuItem key={item.id} onClick={() => handleCloseLangMenu(item.code)}>
            <Typography className={styles.upperCase} textAlign="center">
              {item.code}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
