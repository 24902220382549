import { Box, Tab, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import cx from 'classnames';
import TabPanel from '@mui/lab/TabPanel';
import { useTranslation } from 'react-i18next';
import { TabContext, TabList } from '@mui/lab';
import StatisticsItem from 'components/pageLayout/statistics/statisticsItem/statisticsItem';
import styles from './tabs.module.scss';

interface TabsProps {
  classes?: string;
  title?: string;
  tabMenu?: any[];
  tabContent?: any[];
  translateFrom: string;
  isVertical?: boolean;
  activeTab?: string;
}

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

function Tabs({
  tabMenu,
  title,
  tabContent,
  translateFrom,
  classes,
  isVertical,
  activeTab,
}: TabsProps) {
  const { t } = useTranslation(`${translateFrom}`);
  const [value, setValue] = useState('');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  useEffect(() => {
    setValue(activeTab ?? '0');
  }, [activeTab]);

  return (
    <Box className={cx(styles.container, classes)}>
      {title ? (
        <Box className={styles.title}>
          <Typography align="center">{title}</Typography>
        </Box>
      ) : null}

      <TabContext value={value}>
        <Box className={cx({ [styles.leftSide]: isVertical })}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList
              className={cx({ [styles.rounded]: true })}
              onChange={handleChange}
              orientation={isVertical ? 'vertical' : 'horizontal'}
              sx={{
                '.MuiTabs-flexContainer': {
                  display: 'flex',
                  justifyContent: 'center',
                },
              }}
            >
              {tabMenu?.map(({ name }, index) => {
                return (
                  <Tab
                    key={index}
                    value={index + ''}
                    label={t(name)}
                    {...a11yProps(0)}
                    sx={{ alignItems: 'start' }}
                  />
                );
              })}
            </TabList>
          </Box>
        </Box>
        <Box className={cx({ [styles.rightSight]: isVertical })}>
          {tabContent ? (
            tabContent?.map(({ component }, index) => {
              return (
                <Box key={index}>
                  <TabPanel value={index + ''}>{component}</TabPanel>
                </Box>
              );
            })
          ) : (
            <StatisticsItem value={value} translaste={translateFrom} />
          )}
        </Box>
      </TabContext>
    </Box>
  );
}

export default Tabs;
