import {
  Box,
  Collapse,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import logo from 'assets/logo/logo.svg';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import logoMobile from 'assets/logo/logo.svg';
import footerCircle from 'assets/icons/footerCircle.svg';
import LanguageMenu from 'components/languageMenu/languageMenu';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { FIELD_NAME } from 'types/login';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { ProvidersCarousel } from 'components/carousel/providersCarousel';
import { useProviders } from 'hooks/providersHooks/useProviders';
import { routing } from 'constants/routing';
import { useAuthContext } from 'context/auth/authContext';
import { useModalContext } from 'context/modal/modalContext';
import { useSelector } from 'react-redux';
import { ISiteSocial } from 'types/site';
import styles from './footer.module.scss';

const moke_data = [
  {
    id: '1',
    menu: 'help',
    link: '',
    subMenu: [
      { id: '1', link: '/', menu: 'support' },
      { id: '1', link: '/slots', menu: 'FAQ' },
    ],
  },
  {
    id: '2',
    menu: 'info',
    link: '',
    subMenu: [
      { id: '2', link: '', menu: 'payment' },
      { id: '2', link: '/terms', menu: 'termsAndConditions' },
      { id: '2', link: '/bonusTerms', menu: 'bonusTermsAndConditions' },
      { id: '2', link: '', menu: 'FAQ' },
      { id: '2', link: '/aboutUs', menu: 'aboutUs' },
    ],
  },
];

function Footer() {
  const { t: menuT } = useTranslation('footer');
  const year = new Date().getFullYear();
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const { openModalWithData } = useModalContext();
  const [openedIndex, setOpenedIndex] = useState('');
  const { getAllProvidesWithoutGames } = useProviders();
  const [showButton, setShowButton] = useState<boolean>(false);

  const providersWithoutGames = useSelector<any>((state) => state.providersWithoutGames) as any;
  const { socials } = useSelector<any>((state) => state.siteConfigs) as { socials: ISiteSocial[] };

  const handleOnClick = (event: React.MouseEvent<HTMLElement>, name: string) => {
    setOpenedIndex('');
    if (name === 'payment') {
      event.preventDefault();
      if (user) {
        navigate(routing.payment);
      } else {
        openModalWithData(FIELD_NAME.LOGIN, {
          redirect: routing.payment,
        });
      }
    }
  };

  const handleClick = (id: string) => {
    if (id === openedIndex) {
      setOpenedIndex('');
    } else {
      setOpenedIndex(id);
    }
  };

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    getAllProvidesWithoutGames();

    const handleScrollButtonVisiblity = () => {
      window.pageYOffset > 400 ? setShowButton(true) : setShowButton(false);
    };

    window.addEventListener('scroll', handleScrollButtonVisiblity);

    return () => {
      window.removeEventListener('scroll', handleScrollButtonVisiblity);
    };
  }, []);

  return (
    <Box className={styles.general_area}>
      {showButton && (
        <IconButton className={styles.scroll_to_up} onClick={handleScrollToTop}>
          {<ExpandLessIcon fontSize="large" />}
        </IconButton>
      )}
      <Box className={styles.content}>
        <Box className={styles.community_area}>
          <Box className={styles.logo_area}>
            <img src={logo} className={styles.logo} />
            <img src={logoMobile} className={styles.logoMobile} />
          </Box>
          <Box className={styles.community_info}>
            {socials?.map((social: ISiteSocial) => {
              return (
                <Box key={social.id} sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                  <img className={styles.community_icon} src={social.image} alt="communityIcon" />
                  <Link
                    to={social.url}
                    rel="noopener"
                    target="_blank"
                    className={styles.links_title}
                  >
                    {menuT(social.name)}
                  </Link>
                </Box>
              );
            })}
          </Box>
        </Box>
        <Box className={styles.mobile_version}>
          {moke_data.map(({ id, menu, subMenu }, index) => {
            return (
              <Box key={index} className={styles.main_span}>
                <ListItemButton key={`sub${index}`} onClick={() => handleClick(id)}>
                  <ListItemText
                    primary={menuT(menu)}
                    sx={{
                      span: {
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      },
                    }}
                  />
                  {openedIndex === id ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                {subMenu.map(({ menu, link }, index) => {
                  return (
                    <Collapse
                      key={`subItem${index}`}
                      in={openedIndex === id}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List component="div">
                        <Link to={link} color={'inherit'} title={menuT(menu)}>
                          <ListItemButton sx={{ pl: 4 }}>
                            <ListItemText
                              primary={menuT(menu)}
                              sx={{
                                span: {
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                },
                              }}
                              onClick={(e) => handleOnClick(e, menu)}
                            />
                          </ListItemButton>
                        </Link>
                      </List>
                    </Collapse>
                  );
                })}
              </Box>
            );
          })}
        </Box>
        {moke_data.map(({ menu, subMenu }, i) => {
          return (
            <Box className={styles.links_div} key={i}>
              <Typography className={styles.links_title}>{menuT(menu)}</Typography>
              {subMenu.map(({ menu, link }, i) => {
                return (
                  <Typography className={styles.links} key={i}>
                    <Link to={link} onClick={(e) => handleOnClick(e, menu)}>
                      {menuT(menu)}
                    </Link>
                  </Typography>
                );
              })}
            </Box>
          );
        })}
        <Box className={styles.links_div}>
          <Typography className={styles.links_title}>{menuT('languages')}</Typography>
          <LanguageMenu isMobile />
        </Box>
      </Box>

      <Box sx={{ width: '80%', margin: 'auto' }}>
        <ProvidersCarousel carouselItems={providersWithoutGames} withoutGames />
      </Box>

      <Box className={styles.site_info}>
        <img src={footerCircle} />
        <Typography className={styles.text}>2023-{year} Magnit-X</Typography>
      </Box>
    </Box>
  );
}

export default Footer;
