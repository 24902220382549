import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { useStorage } from 'hooks/authHooks/useStorage';
import styles from './bonusTerms.module.scss';

const mocked_terms = {
  ru: [
    {
      id: '1',
      title: 'Правила и условия для бонусов',
      content: `Максимальный выигрыш с отыгранного  бонуса составляет вайджер х2  до  10 000 RUB 
      или эквивалентную сумму в другой валюте. 
      Любой остаток, превышающий указанные лимиты, будет списан или аннулировано  с баланса.
        Пожалуйста, имейте в виду, что выигрыш, полученный с  бонуса, следует сначала вывести, 
        а затем, при желании, сделать новый депозит, чтобы продолжить игру без ограничений по выигрышу.`,
    },
  ],
  en: [
    {
      id: '1',
      title: 'Bonus terms and conditions',
      content: `The maximum win from a wagered bonus is a wager x2 up to 10,000 rubles or an equivalent amount in another currency.
          Any balance exceeding these limits will be debited or canceled from your balance.
          Please keep in mind that winnings received from a bonus must first be withdrawn and then, 
          if desired, made a new deposit in order to continue playing without any winning limits.`,
    },
  ],
} as { [x: string]: any };

function BonusTerms() {
  const { t: footerT } = useTranslation('footer');
  const { t } = useTranslation('common');
  const { readStorageData } = useStorage();
  const lang = readStorageData('siteLanguage') ?? 'ru';

  const term = useMemo(() => {
    const key = lang as string;
    return mocked_terms[key].map((el: any) => {
      return (
        <Typography key={el.id} component="p">
          {el.content}
        </Typography>
      );
    });
  }, [lang]);

  return (
    <Box className={styles.terms_area}>
      <Box className={styles.main_info}>
        <Box className={styles.titles_info}>
          <Box>
            <Typography variant="h4">{footerT('bonusTermsAndConditions')}</Typography>
            <Typography component={'p'}>{t('termsSlogon')}</Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={{ mt: 3, minHeight: '250px' }}>{term}</Box>
    </Box>
  );
}

export default BonusTerms;
