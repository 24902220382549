export const common = {
  YES: 'yes',
  NO: 'no',
  TRUE: 'true',
  FALSE: 'false',
  ASC: 'ASC',
  DESC: 'DESC',
  SITE: 'site',
  NEW: 'new',
  ADD: 'Add',
};

export const routeSwitcher = {
  ACCOUNT_PAGE: 'accountsPage',
  SITE_PAGE: 'sitesPage',
  SITE_SETTINGS: 'sitesSettingsPage',
  WELCOME_PAGE: 'welcomePage',
  PERMISSON_MANAGMENT: 'permission managment',
  STATISTICS: 'statistics',
  CHANNEL: 'telegram channel',
  RAFFLE: 'telegram raffle',
  GAMBLING_STATISTICS: 'gambling statistics',
};

export const dialog = {
  WANT_EXIT: 'wantExit',
  CHOOSE_BALANCE: 'chooseBalance',
  SELECT_DENOMINATION: 'selectDenomination',
  WHEEL_TITLE: 'wheelTitle',
  WHEEL_CONTENT: 'wheelDialogContent',
  WHEEL_TITLE_WIN: 'wheelTitleWin',
  WHEEL_CONTENT_WIN: 'wheelDialogContentWin',
  WHEEL_CONTENT_DELAY:'wheelDialogContentDelay'
};

type IErrors = {
  [key: string]: string
}

export const requestResults: IErrors = {
  "Something went wrong": "somethingWentWrong"
}

export const mobileMenuItems = {
    LOBBY: 'lobby',
    STATISTICS: 'statistics',
    TELEGRAM: 'telegram'
}