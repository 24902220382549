import { Box, Radio } from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ICheckBoxes } from 'types/signUp';
import RUB from 'assets/icons/currencies/rub.png';
import EURO from 'assets/icons/currencies/euro.png';
import USD from 'assets/icons/currencies/usd.png';
import styles from './checkbox.module.scss';

interface CheckboxesProps {
  currIds?: number[];
  onChange?: (id: number) => void;
}

const currenciesIcons = [
  { icon: RUB, code: 'RUB' },
  { icon: EURO, code: 'EUR' },
  { icon: USD, code: 'USD' },
];

export const CurrencyCheckboxes = ({ onChange }: CheckboxesProps) => {
  const [checkedId, setCheckedId] = useState<number | null>(null);
  const { siteCurrencies } = useSelector((state: any) => state.siteCurrencies) as {
    siteCurrencies: ICheckBoxes[];
  };

  const handleOnChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id } = event.target;
    onChange?.(+id);
    setCheckedId(+id);
  };

  return (
    <Box className={styles.currencies}>
      {siteCurrencies?.map(({ code, id, icon }, index) => {
        return (
          <Box key={index} className={styles.content}>
            <img
              src={icon}
              alt={code}
              width={15}
              height={15}
              className={styles.svg}
              onError={({ currentTarget }: any) =>
                (currentTarget.src =
                  currenciesIcons.filter((el) => el.code === code)?.[0]?.icon ?? '')
              }
            />

            <Radio
              key={id}
              name={code}
              id={id}
              checked={+id === checkedId}
              onChange={handleOnChecked}
              sx={{
                '& .MuiSvgIcon-root': { fontSize: 18 },
                '&.Mui-checked': { color: '#de5000' },
                mr: 1,
                width: '17px',
              }}
            />
          </Box>
        );
      })}
    </Box>
  );
};
