import { Box, Button, Tab, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { isNumber } from 'utils/validations/validationTypes';
import { useTransaction } from 'hooks/transactions/useTransaction';
import { useSelector } from 'react-redux';
import { usePayment } from 'hooks/payment/usePayment';
import { FIELD_NAME, IPayMethods } from 'types/payment';
import common from 'styles/colors.module.scss';
import styles from './deposit.module.scss';

const fields = {
  [FIELD_NAME.MIN]: true,
};

// const bonusList = [
//   {
//     id: '1',
//     title: { val1: 150, val2: 50 },
//     details: { deposit: 7 },
//     bgColor: '#f4433654',
//   },
//   {
//     id: '2',
//     title: { val1: 125, val2: 100 },
//     details: { deposit: 20 },
//     bgColor: '#ff980063',
//   },
//   {
//     id: '3',
//     title: { val1: 150, val2: 150 },
//     details: { deposit: 50 },
//     bgColor: '#f321f145',
//   },
//   {
//     id: '4',
//     title: { val1: 150, val2: 150 },
//     details: { deposit: 50 },
//     bgColor: '#f321f145',
//   },
//   {
//     id: '5',
//     title: { val1: 200, val2: 150 },
//     details: { deposit: 50 },
//     bgColor: '#ff980063',
//   },
//   {
//     id: '6',
//     title: { val1: 120, val2: 150 },
//     details: { deposit: 50 },
//     bgColor: '#f321f145',
//   },
// ];

export const renderItem = (
  isActive: boolean,
  item: IPayMethods,
  typo: string,
  index: number,
  min: number,
  curr: string,
  callBack: (data: IPayMethods) => void,
) => {
  const { image } = item;

  const handleOnClick = () => {
    callBack(item);
  };

  return (
    <Box
      className={cx(styles.deposit_item, { [styles.isActive]: isActive })}
      key={index}
      onClick={handleOnClick}
    >
      <Box sx={{ display: 'flex' }}>
        <img src={image} />
      </Box>
      <Box sx={{ display: 'flex' }}>
        <Typography component="span" sx={{ mr: 0.5 }}>
          {typo}
        </Typography>
        <Typography component="span">
          {min} {curr}
        </Typography>
      </Box>
    </Box>
  );
};

function Deposit() {
  const { t } = useTranslation('deposit');
  const { t: commonT } = useTranslation('common');
  const { t: paymentT } = useTranslation('payment');
  const { t: validationT } = useTranslation('validation');

  const [value, setValue] = useState('0');
  const userData = useSelector<any>((state) => state.userData) as any;
  // TODO remove bonusLimit when BE will be ready and merge two component into one "Deposit and Withdraw"
  const { isLoadingPayIns, methodsList, getAllPayInMethods } = useTransaction();
  const { payIn } = usePayment();
  // const [bonusLimit, setBonusLimit] = useState(3);
  const [payMethodsLimit, setpayMethodsLimit] = useState(7);
  const [activePayment, setAtivePayment] = useState<IPayMethods>();
  const [quantity, setQuantity] = useState('');
  // const [selectedItemId, setSelectedItemId] = useState<null | number>(null);
  const [validFields, setValidFields] = useState({ ...fields });
  const [errorName, setErrorName] = useState('');

  const walletAmount = userData?.balance.filter((el: any) => el.id === 3)?.[0]?.amount;

  const resetValidFields = () => {
    setErrorName('');
    setValidFields((fields) => ({
      ...fields,
      [FIELD_NAME.MIN]: true,
    }));
  };

  const checkValidation = (activePayment: IPayMethods, name: string, value: string) => {
    let isValid = true;

    switch (name) {
      case FIELD_NAME.CARD:
        isValid = value.length === 19;
        break;
      case FIELD_NAME.MIN:
        if (+value >= activePayment?.sitePayInMethods?.[0]?.maxValue) {
          setErrorName('isBiggerMaxVal');
        } else if (+value <= activePayment?.sitePayInMethods?.[0]?.minValue) {
          setErrorName('isSmallerMinVal');
        }
        isValid =
          +value >= activePayment?.sitePayInMethods?.[0]?.minValue &&
          +value <= activePayment?.sitePayInMethods?.[0]?.maxValue;

        break;
      default:
    }
    return isValid;
  };

  const validate = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, name: string) => {
    const { value } = e.target;

    if (activePayment && value !== '') {
      setValidFields((fields) => ({
        ...fields,
        [name]: checkValidation(activePayment, name, value),
      }));
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    setQuantity('');
    resetValidFields();
  };

  const handleOnClick = async () => {
    if (activePayment && +quantity !== 0) {
      const body = { amount: +quantity };
      const res = await payIn(activePayment?.id, body);
      if (res.status === 200) {
        window.open(res.data.metadata.paymentURL, '_self');
      }
    } else if (quantity === '') {
      setValidFields((fields) => ({
        ...fields,
        [FIELD_NAME.MIN]: false,
      }));
    }
  };

  const handleOnChangeMin = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value } = e.target;
    if (isNumber(value)) {
      setQuantity(value);
    }

    resetValidFields();
  };

  // const handleOnClickBonus = (id: number | null) => {
  //   setSelectedItemId(id);
  // };

  useEffect(() => {
    const isCripto = !!+value;
    getAllPayInMethods(`isCrypto=${isCripto}`);
  }, [value]);

  useEffect(() => {
    if (methodsList.length) {
      setAtivePayment(methodsList[0]);
    }
  }, [methodsList]);

  return (
    <Box className={styles.main_area}>
      <Box className={styles.main_title}>{t('wallet')}</Box>
      <Box className={styles.methods_block}>
        <Box className={styles.header}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box className={styles.title}>{paymentT('title')}</Box>
            <Box className={styles.bullet} />
          </Box>
          <Box className={styles.current_amount}>
            <Typography>{t('currAmount')}:</Typography>
            <Typography sx={{ color: common.mainOrange, ml: 2, fontWeight: 800 }}>
              {walletAmount} {userData?.currency?.code}
            </Typography>
          </Box>
        </Box>

        {methodsList.length > payMethodsLimit ? (
          <Box className={styles.show_more} onClick={() => setpayMethodsLimit(methodsList.length)}>
            {commonT('showMore')}
          </Box>
        ) : null}

        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange}>
              {[{ name: 'money' }, { name: 'crypto' }].map(({ name }, index) => {
                return (
                  <Tab
                    key={index}
                    value={index + ''}
                    label={t(name)}
                    sx={{ alignItems: 'start' }}
                  />
                );
              })}
            </TabList>
          </Box>

          <TabPanel value="0" className={styles.own_tab}>
            <Box className={styles.deposit_block}>
              {isLoadingPayIns ? (
                <Box className={styles.loading}>{commonT('loading')}</Box>
              ) : (
                methodsList.map((item: IPayMethods, index: number) => {
                  return index < payMethodsLimit
                    ? renderItem(
                        activePayment?.id === item.id,
                        item,
                        t('min'),
                        index,
                        item.sitePayInMethods[0].minValue,
                        item.sitePayInMethods[0].currencyCode,
                        setAtivePayment,
                      )
                    : null;
                })
              )}
            </Box>
          </TabPanel>
          <TabPanel value="1" className={styles.own_tab}>
            <Box className={styles.deposit_block}>
              {isLoadingPayIns ? (
                <Box className={styles.loading}>{commonT('loading')}</Box>
              ) : (
                methodsList.map((item: IPayMethods, index: number) => {
                  return index < payMethodsLimit
                    ? renderItem(
                        activePayment?.id === item.id,
                        item,
                        t('min'),
                        index,
                        item.sitePayInMethods[0].minValue,
                        item.sitePayInMethods[0].currencyCode,
                        setAtivePayment,
                      )
                    : null;
                })
              )}
            </Box>
          </TabPanel>
        </TabContext>
        <Box className={styles.promo}>
          <TextField
            label={t('count', {
              val: activePayment?.sitePayInMethods[0].minValue,
              curr: activePayment?.sitePayInMethods[0].currencyCode,
            })}
            size="small"
            fullWidth
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            value={quantity}
            onBlur={(e) => validate(e, FIELD_NAME.MIN)}
            error={!validFields[FIELD_NAME.MIN]}
            onChange={handleOnChangeMin}
          />
          <Box className={styles.currency_name}>{userData?.currency?.code}</Box>
        </Box>
        {!validFields[FIELD_NAME.MIN] && errorName === 'isSmallerMinVal' ? (
          <Typography className={styles.error}>
            {validationT('minMustBe', { length: activePayment?.sitePayInMethods[0].minValue })} {commonT("forDeposit")}
          </Typography>
        ) : !validFields[FIELD_NAME.MIN] && errorName === 'isBiggerMaxVal' ? (
          <Typography className={styles.error}>
            {validationT('maxMustBe', { length: activePayment?.sitePayInMethods[0].maxValue })} {commonT("forDeposit")}
          </Typography>
        ) : null}
        <Button
          sx={{ mt: 2 }}
          fullWidth
          variant="contained"
          onClick={handleOnClick}
          disabled={!validFields[FIELD_NAME.MIN]}
        >
          {t('deposit')}
        </Button>
      </Box>
      {/* need uncomment later */}
      {/* <Box>
        <Box className={styles.bonus}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box className={styles.title}>{t('getBonus')}</Box>
            <Box className={styles.bullet} />
            {bonusList.length > bonusLimit ? (
              <Box className={styles.show_more} onClick={() => setBonusLimit(10)}>
                {commonT('showMore')}
              </Box>
            ) : null}
          </Box>
          <Box className={styles.bonus_block}>
            {bonusList.map(({ id, title, details, bgColor }, index) => {
              return index < bonusLimit ? (
                <Box
                  className={cx(styles.bonus_item, { [styles.isActive]: +id === selectedItemId })}
                  key={index}
                  sx={{ backgroundColor: `${bgColor}` }}
                  onClick={() => handleOnClickBonus(+id)}
                >
                  <Typography component="span" sx={{ mr: 0.5, fontWeight: 900, fontSize: '14px' }}>
                    {t('bonusTitle', { ...title })}
                  </Typography>
                  <Typography component="span"> {t('depositCount', { ...details })}</Typography>
                </Box>
              ) : null;
            })}
          </Box>
        </Box>
        <Button sx={{ mt: 2 }} fullWidth variant="contained" disabled={!selectedItemId}>
          {commonT('applyBonus')}
        </Button>
      </Box> */}
    </Box>
  );
}

export default Deposit;
