import { Box, IconButton, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { IconClose } from 'components/closeButton/closeButton';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import { ReactComponent as DisLikeIconEmpty } from 'assets/icons/middle_finger_empty.svg';
import { ReactComponent as DisLikeIconFull } from 'assets/icons/middle_finger_full.svg';
import { ReactComponent as FullScreenIcon } from 'assets/icons/fullscreen.svg';
import { useEffect, useRef, useState } from 'react';
import { useGame } from 'hooks/playGames/useGame';
import { routing } from 'constants/routing';
import { useTranslation } from 'react-i18next';
import common from 'styles/colors.module.scss';
import LoaderWithLogo from 'components/loader/loaderWithLogo';
import styles from './playGame.module.scss';

export const PlayGame = () => {
  const { t } = useTranslation('common');
  const containerRef = useRef<HTMLIFrameElement>(null);
  const navigate = useNavigate();
  const { playGame, closeGame, changeGameFavoriteStatus, changeGameUnlikedStatus } = useGame();
  const { state } = useLocation();
  const [iframeUrl, setIframeUrl] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [image, setImage] = useState('');
  const [gameName, setGameName] = useState('');
  const [favoriteCount, setGameIsFavoriteCount] = useState('');
  const [unlikedCount, setGameUnlikedCount] = useState('');
  const [isLiked, setIsLiked] = useState<boolean>(state?.isLiked ?? false);
  const [isUnliked, setIsUnliked] = useState<boolean>(state?.isUnliked ?? false);
  let timeOutId: ReturnType<typeof setTimeout>;
  let timeOutForLoading: ReturnType<typeof setTimeout>;

  const [sessionId, setSessionId] = useState('');
  const [isFullScreen, setIsFullScreen] = useState(false);

  const handleClose = async () => {
    const res = await closeGame(sessionId);
    if (res?.status === 200) {
      navigate(`${routing.home}`, {
        state: { ...state },
      });
    }
  };

  const toggleFullscreen = () => {
    const container: any = containerRef.current;

    if (!document.fullscreenElement) {
      if (container.requestFullscreen) {
        container.requestFullscreen();
      } else if (container.webkitRequestFullscreen) {
        container.webkitRequestFullscreen();
      } else if (container.mozRequestFullScreen) {
        container.mozRequestFullScreen();
      } else if (container.msRequestFullscreen) {
        container.msRequestFullscreen();
      }
    } else {
      document.exitFullscreen();
    }

    timeOutId = setTimeout(() => {
      setIsFullScreen(document.fullscreenElement === containerRef.current);
    }, 100);
  };

  const updateUnLikedStatus = async (status: boolean) => {
    const res = await changeGameUnlikedStatus(state.gameId, status);
    if (res?.status === 200) {
      setIsUnliked(status);
      setIsLiked(false);
      if (status && favoriteCount != '0' && isLiked) {
        setGameIsFavoriteCount(`${+favoriteCount - 1}`);
        await changeGameFavoriteStatus(state.providerId, state.gameId, { isFavorite: false });
      }
      if (status) {
        setGameUnlikedCount(`${+unlikedCount + 1}`);
      } else if (!status && +unlikedCount !== 0) {
        setGameUnlikedCount(`${+unlikedCount - 1}`);
      }
    }
  };

  const updateFavoriteStatus = async (like: boolean) => {
    const body = { isFavorite: like };
    const res = await changeGameFavoriteStatus(state.providerId, state.gameId, body);
    if (res?.status === 200) {
      setIsLiked(like);
      setIsUnliked(false);
      if (like && unlikedCount != '0' && isUnliked) {
        setGameUnlikedCount(`${+unlikedCount - 1}`);
        await changeGameUnlikedStatus(state.gameId, false);
      }
      if (like) {
        setGameIsFavoriteCount(`${+favoriteCount + 1}`);
      } else if (!like && +favoriteCount !== 0) {
        setGameIsFavoriteCount(`${+favoriteCount - 1}`);
      }
    }
  };

  useEffect(() => {
    if (state) {
      const getGameData = async (gameId: string, body: { balanceId: string }) => {
        const res = await playGame(gameId, body);
        if (res?.status === 201) {
          const { data } = res;
          setIframeUrl(data.launchURL);
          setSessionId(data.id);
          setImage(data.game.image);
          setGameIsFavoriteCount(data.game.userFavoriteGamesCount);
          setIsLiked(data.game.isFavorite);
          setIsUnliked(data.game.isUnliked);
          setGameUnlikedCount(data.game.userUnlikedGamesCount);
          timeOutForLoading = setTimeout(() => {
            setIsLoading(false);
          }, 2000);
        }
      };

      if (state?.launchURL && state?.sessionId) {
        setIframeUrl(state.launchURL);
        setSessionId(state.sessionId);
        setIsLiked(state.isLiked);
        setIsUnliked(state.isUnliked);
        setImage(state.image);
        setGameIsFavoriteCount(state.gameCounts.favoriteCount);
        setGameUnlikedCount(state.gameCounts.unlikedCount);
        timeOutForLoading = setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      } else {
        if (state?.gameId !== undefined && state?.balanceId !== undefined) {
          const body = { balanceId: state?.balanceId, denomination: state?.denomination };
          getGameData(state.gameId, body);
          setImage(state.image);
          setGameName(state.name);
        }
      }

      return () => {
        clearTimeout(timeOutForLoading);
        clearTimeout(timeOutId);
      };
    } else {
      navigate(routing.home);
    }
  }, [state]);

  return (
    <>
      {isLoading ? <LoaderWithLogo isOpen={isLoading} /> : null}
      <Box sx={{ width: '100%', display: 'flex' }}>
        <Box className={styles.play_games_container}>
          <Box className={styles.iframe}>
            <Box className={styles.content} ref={containerRef}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, pr: isFullScreen ? 1 : 0 }}>
                {isFullScreen && (
                  <FullscreenExitIcon
                    onClick={toggleFullscreen}
                    sx={{ cursor: 'pointer', mt: 1, fontSize: '35px' }}
                  />
                )}
                {sessionId || iframeUrl ? (
                  <IconClose className={styles.iframe_close_btn} onClose={handleClose} />
                ) : null}
              </Box>
              <iframe style={{ border: 'none' }} src={iframeUrl} allowFullScreen seamless />
            </Box>
            <Box className={styles.fullscreen}>
              <FullScreenIcon onClick={toggleFullscreen} />{' '}
              <IconButton
                aria-label="add to favorites"
                sx={{ color: '#fff' }}
                onClick={() => updateFavoriteStatus(!isLiked)}
              >
                {isLiked ? (
                  <FavoriteIcon sx={{ width: '18px' }} />
                ) : (
                  <FavoriteBorderIcon sx={{ width: '18px' }} />
                )}
              </IconButton>
              <Typography
                component={'span'}
                color={common.mainOrange}
              >{`(${favoriteCount})`}</Typography>
              <IconButton
                aria-label="add to disliked"
                sx={{ color: '#fff' }}
                onClick={() => updateUnLikedStatus(!isUnliked)}
              >
                {isUnliked ? <DisLikeIconFull /> : <DisLikeIconEmpty />}
              </IconButton>
              <Typography
                component={'span'}
                color={common.mainOrange}
              >{`(${unlikedCount})`}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
