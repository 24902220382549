import { Box, Button, IconButton, Menu, MenuItem, Skeleton, Typography } from '@mui/material';
import union from 'assets/icons/union.svg';
import cx from 'classnames';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FIELD_NAME_PROFILES } from 'types/user';
import { useModalContext } from 'context/modal/modalContext';
import { useDispatch, useSelector } from 'react-redux';
import { routing } from 'constants/routing';
import { useUser } from 'hooks/user/useUser';
import { useNavigate } from 'react-router-dom';
import { socket } from 'App';
import { updateUserBalance } from 'redux/actions/userDataAction';
import { useStorage } from 'hooks/authHooks/useStorage';
import { STORAGE_KEYS, localStorageService } from 'utils/storageService';
import styles from './balanceCheck.module.scss';

export default function BalanceCheck() {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const { removeUser } = useUser();
  const dispatch = useDispatch();
  const { updateData, setUserBalanceType } = useStorage();
  const { openModalWithData } = useModalContext();
  const [typeWallet, setTypeWallet] = useState('');
  const [walletBalance, setWalletBalance] = useState('');
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);

  const userData = useSelector<any>((state) => state.userData) as any;

  const handleOpenBalance = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorElNav(event.currentTarget);

  };

  const handleOnOpenBalance = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    if (anchorElNav === null) {
      setAnchorElNav(event.currentTarget);
    }
    else {
      setAnchorElNav(null);
    }
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleMenuItemClick = (type: string, balanceSum: number) => {
    setTypeWallet(type);
    setWalletBalance(balanceSum + '');
    setUserBalanceType(type);
    handleCloseNavMenu();
  };

  const handleOpenBalanceTransfer = () => {
    openModalWithData(FIELD_NAME_PROFILES.DEPOSIT, null);
    return handleCloseNavMenu();
  };

  useEffect(() => {
    socket.on('balance', (data) => {
      dispatch(updateUserBalance(data));
      updateData({ balance: data });
    });
  }, []);

  useEffect(() => {
    /**TODO remove this if condition after a certain time, we need this  for clearing old data from already logged users */
    if (typeof userData?.user?.balance === 'string') {
      removeUser();
      navigate(`${routing.home}`);
    } else {
      const walletType =
        localStorageService.read(STORAGE_KEYS.BALANCE_TYPE) ?? userData?.balance?.[0]?.type;
      const selectedWallet = userData?.balance?.filter((elm: any) => elm.type === walletType);
      if (selectedWallet?.length) {
        setTypeWallet(selectedWallet[0].type);
        setWalletBalance(selectedWallet[0].amount);
      }
    }
  }, [userData]);

  return (
    <>
      {!walletBalance && !typeWallet ? (
        <Skeleton variant="rounded" sx={{ borderRadius: '16px' }}>
          <Box className={styles.general_area}>
            <Box className={styles.all_boxes}></Box>
          </Box>
        </Skeleton>
      ) : (
        <Box className={styles.general_area} onClick={handleOnOpenBalance}>
          <Box className={styles.all_boxes}>
            <Box>
              <img style={{ height: '15px', width: '22px', marginTop: '5px' }} src={union} />
            </Box>
            <Box className={styles.balance_info}>
              <Typography sx={{ display: 'flex', flexDirection: 'column' }}>
                <span className={styles.wallet_balance}>
                  {walletBalance} {userData?.currency?.code}
                </span>
                <span className={styles.wallet_type}>{t(typeWallet)}</span>
              </Typography>
            </Box>
            <Box>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar-balance"
                aria-haspopup="true"
                style={{ height: '17px', width: '17px' }}
                onClick={handleOpenBalance}
                color="inherit"
                className={cx({ [styles.rotate]: anchorElNav })}
              >
                <KeyboardArrowDownIcon />
              </IconButton>
              <Menu
                sx={{ ul: { width: '280px' } }}
                id="menu-appbar-balance"
                anchorEl={anchorElNav}
                open={Boolean(anchorElNav)}
                anchorOrigin={{
                  vertical: 40,
                  horizontal: -60,
                }}
                keepMounted
                onClose={handleCloseNavMenu}
              >
                {userData?.balance?.map((elm: { type: string; amount: number }, i: number) => {
                  return (
                    <MenuItem key={i} onClick={() => handleMenuItemClick(elm.type, elm.amount)}>
                      <Box
                        textAlign="center"
                        className={styles.balance_items}
                        sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
                      >
                        <Typography className={styles.wallet_type}>{t(elm.type)}</Typography>
                        <Typography sx={{ ml: 1 }}>
                          {elm.amount} {userData?.currency?.code}
                        </Typography>
                      </Box>
                    </MenuItem>
                  );
                })}
                <Box sx={{ display: 'flex', padding: '8px 16px' }}>
                  <Button
                    size="small"
                    fullWidth
                    className="App-btn red"
                    sx={{ margin: '0 auto', color: '#fff' }}
                    onClick={handleOpenBalanceTransfer}
                  >
                    {t('balanceTransfer')}
                  </Button>
                </Box>
              </Menu>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}
