import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { Banner } from 'components/banner/banner';
import { CarouselVertical } from 'components/carousel/carouselVertical';
import WinnersTab from 'components/winnersTab/winnersTab';
import { useStorage } from 'hooks/authHooks/useStorage';
import { socket } from 'App';
import { updateUserLevel } from 'redux/actions/userDataAction';
import { IContent } from 'components/tooltip/tooltip';
import styles from './topSections.module.scss';

interface SectionTopProps {
  withBanner?: boolean;
}
export interface IBanner {
  img: string;
}

interface IBannerBtn {
  buttonColor: string;
  text: string;
  link: string;
  links?: IContent[];
  type?: string;
  routeData?: any;
}

export interface ISelectedBanner {
  itemName: string;
  bannerImage: IBanner[];
  titleText: string;
  secondaryText: any;
  button: IBannerBtn[];
}

const moked_data = {
  itemName: '',
  bannerImage: [],
  titleText: '',
  secondaryText: [],
  button: [{ buttonColor: '', text: '', link: '' }],
};

export const TABLET = 900;

export const TopSection = ({ withBanner }: SectionTopProps) => {
  const [selectedItem, setSelectedItem] = useState<ISelectedBanner>(moked_data);
  const [lineStatus, setLineStatus] = useState(
    window.innerWidth >= TABLET ? 'vertical' : 'horizontal',
  );
  const [isMobile, setIsMobile] = useState(false);
  const dispatch = useDispatch();
  const { updateData } = useStorage();

  const { device } = useSelector<any>((state) => state.device) as {
    device: string;
  };

  function handleWindowResizing() {
    window.innerWidth <= TABLET ? setLineStatus('horizontal') : setLineStatus('vertical');
    setIsMobile(window.innerWidth <= TABLET);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowResizing);
    socket.on('levelGroup', (data) => {
      dispatch(updateUserLevel(data));
      updateData({ levelGroup: data });
    });

    return () => {
      window.removeEventListener('resize', handleWindowResizing);
    };
  }, []);

  const changeSelectedItem = (selectedItem: ISelectedBanner) => {
    setSelectedItem(selectedItem);
  };

  return (
    <Box className={styles.main_container}>
      <Box sx={{ width: !withBanner ? '128px' : '', display: { md: 'flex' } }}>
        <CarouselVertical onChange={changeSelectedItem} lineStatus={lineStatus} />
      </Box>
      <Box sx={{ display: 'flex' }}>
        {!withBanner ? (
          <Box>
            <Banner selectedItem={selectedItem} />
          </Box>
        ) : null}
        {!isMobile ? (
          <Box sx={{ display: { xs: 'none', sm: 'none', md: 'flex' } }}>
            <WinnersTab />
          </Box>
        ) : null}
      </Box>
      {device === 'Mobile' || isMobile ? (
        <Box sx={{ mt: 2, display: { xs: 'flex', sm: 'flex', md: 'none' } }}>
          <WinnersTab isMobile={device === 'Mobile' || isMobile} />
        </Box>
      ) : null}
    </Box>
  );
};
