import { Box, Typography, IconButton, InputBase, Paper, Stack, Button } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { useAuthContext } from 'context/auth/authContext';
import SearchIcon from '@mui/icons-material/Search';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { useCategory } from 'hooks/categoryGames/useCategory';
import Icon7 from 'assets/icons/defaultMenuIcons/7.svg';
import { useSelector } from 'react-redux';
import { IGame } from 'types/game';
import color from 'styles/colors.module.scss';
import GameCard from 'components/gameCard/gameCard';
import { useUserData } from 'hooks/user/useUserData';
import { makeQueryUrl } from 'utils/utils';
import { useSubProviderGames } from 'hooks/subProviderGames/useSubProviderGames';
import styles from './slots.module.scss';

const useStyles = makeStyles(() => ({
  ul: {
    '& .MuiPaginationItem-root.Mui-selected, & .MuiPaginationItem-root.Mui-selected:hover': {
      color: color.mainOrange,
      backgroundColor: 'unset',
    },
  },
  paper: {
    height: '40px',
    border: 'Thin solid white',
    backgroundColor: 'unset!important',
    '& svg': {
      fill: 'white',
    },
  },
}));

interface IFilterData {
  subProviderId?: string;
  categoryId?: string;
  isFavorite?: boolean;
}

export const SlotsPage = () => {
  const { t } = useTranslation('common');
  const { user } = useAuthContext();
  const [debouncedValue, setDebouncedValue] = useState('');
  const [providerName, setProviderName] = useState('');
  const [gameName, setGameName] = useState<string | null>(null);
  const [filterData, setFilterData] = useState<IFilterData>({});
  const { getAllSubProviderGames, getAllSubProvidersByCategoryId } = useSubProviderGames();
  const [globalTotalPages, setGlobalTotalPages] = useState(null);
  const [activeGames, setActiveGames] = useState<IGame[]>([]);

  const [page, setPage] = useState(1);

  const [activeIconMenu, setActiveIconMenu] = useState<string>('');

  const { device } = useSelector<any>((state) => state.device) as {
    device: string;
  };
  const { isLoading } = useCategory(true, user, device);
  const { isLoading: isLoadingSpecialGames, getUsersRecentGames } = useUserData();

  const { categories } = useSelector<any>((state) => state.categories) as {
    categories: any;
  };
  const providersWithoutGames = useSelector<any>((state) => state.providersWithoutGames) as any;
  const [providersList, setProvidersList] = useState([]);

  const [activeCategory, setActiveCategory] = useState<number | null>(null);
  const [selectedSubProvider, setSelectedSubProvider] = useState<string | null>('');

  const classes = useStyles();

  const handleOnClickSubProvider = (subProviderId: string) => {
    const newData = { ...filterData };
    if ('isFavorite' in newData) {
      delete newData['isFavorite'];
    }
    setFilterData(newData);
    setSelectedSubProvider(subProviderId);
    setActiveIconMenu('');
    setActiveGames([]);
    setPage(1);
    setGameName(null);
  };

  const handleOnClickMore = (event: React.ChangeEvent<unknown>, totalPages: number) => {
    if (totalPages > 1) {
      setPage((value) => (value + 1 <= totalPages ? value + 1 : value));
    }
  };

  const handleOnClickCategory = (categoryId?: number) => {
    setFilterData({});
    setActiveGames([]);
    setActiveIconMenu('');
    setGameName(null);
    setPage(1);
    if (categoryId) {
      setActiveCategory(categoryId as number);
      setSelectedSubProvider(null);
    } else {
      setActiveCategory(null);
      setSelectedSubProvider(providersWithoutGames[0].id);
      setProvidersList(providersWithoutGames);
    }
  };

  useEffect(() => {
    setPage(1);

    const delay = 500;
    const timeoutId = setTimeout(() => {
      if (typeof gameName === 'string') {
        setActiveGames([]);
        setDebouncedValue(gameName);
      } else {
        setDebouncedValue('');
      }
    }, delay);

    return () => clearTimeout(timeoutId);
  }, [gameName]);

  useEffect(() => {
    if (
      activeCategory ||
      Object.keys(filterData).length !== 0 ||
      selectedSubProvider ||
      debouncedValue
    ) {
      let query = debouncedValue ? `&name=${debouncedValue}` : '';
      query += activeCategory ? `&categoryId=${activeCategory}` : '';
      query += selectedSubProvider ? `&subProviderId=${selectedSubProvider}` : '';
      const deviceQuery = device === 'Mobile' ? `&isMobile=true` : `&isDesktop=true`;
      const getData = async () => {
        query += `${makeQueryUrl(filterData)}&page=${page}${deviceQuery}`;
        const newQuery = query.split('');
        newQuery.shift();
        query = newQuery.join('');
        const forPublic = !user ? '/public?' : undefined;
        const result = await getAllSubProviderGames(query, forPublic);
        if (result?.status === 200) {
          const { games, totalPages } = result.data;
          setGlobalTotalPages(totalPages);
          totalPages && totalPages > 1
            ? setActiveGames((activeGames) => (activeGames ? [...activeGames, ...games] : games))
            : setActiveGames(games);
          if (activeCategory) {
            const res = await getAllSubProvidersByCategoryId(activeCategory, deviceQuery);
            if (res?.status === 200) {
              setProvidersList(
                providersWithoutGames?.filter((elm: any) => res?.data?.includes(elm.id)) ?? [],
              );
            }
          }
        }
      };

      if (!isLoading) {
        getData();
      }
    }
  }, [isLoading, filterData, page, debouncedValue, activeCategory, selectedSubProvider]);

  const changeActiveIconMenu = (name: string) => {
    setActiveIconMenu(name);
    setPage(1);
    if (name === 'favoriteGames') {
      setActiveGames([]);
      setFilterData((filterData) => ({ ...filterData, isFavorite: true }));
    } else {
      if (activeIconMenu !== 'resentGames') {
        setFilterData({});
        setSelectedSubProvider(null);
        setActiveCategory(0);
        const getData = async () => {
          const { data } = await getUsersRecentGames();
          setActiveGames(data);
          setGlobalTotalPages(null);
        };
        getData();
      }
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  const handleGameSearch = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value } = e.target;

    setGameName(value);
  };

  const handleProviderSearch = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value } = e.target;
    if (value) {
      setProvidersList(
        providersWithoutGames.filter((elm: any) =>
          elm.name.toLocaleLowerCase().includes(value.toLocaleLowerCase()),
        ),
      );
    } else {
      setProvidersList(providersWithoutGames);
    }
    setProviderName(value);
  };

  useEffect(() => {
    if (providersWithoutGames && categories.length) {
      setSelectedSubProvider(providersWithoutGames[0]?.id);
      setProvidersList(providersWithoutGames);
    }
  }, [categories, providersWithoutGames]);

  return (
    <Box className={styles.main}>
      <Box className={styles.left_side}>
        <Box className={styles.category_list}>
          <Box
            className={cx(styles.category_logo, {
              [styles.active]: activeCategory === null,
            })}
            onClick={() => handleOnClickCategory()}
          >
            <img src={Icon7} alt="game" />
            <Typography align="center">All</Typography>
          </Box>
          {categories?.map((category: any, index: number) => {
            return (
              <Box
                key={index}
                className={cx(styles.category_logo, {
                  [styles.active]: category.id === activeCategory,
                })}
                onClick={() => handleOnClickCategory(category.id)}
              >
                <img src={category.image ?? Icon7} alt="game" />
                <Typography align="center">{category.title}</Typography>
              </Box>
            );
          })}
        </Box>

        <Box className={styles.provider_list}>
          <Paper
            onSubmit={handleSubmit}
            className={classes.paper}
            component="form"
            sx={{ display: 'flex', alignItems: 'center', width: '100%', mb: 3 }}
          >
            <InputBase
              size="small"
              fullWidth
              sx={{ ml: 1, flex: 1 }}
              placeholder={t('searchProv')}
              value={providerName}
              inputProps={{ 'aria-label': 'search prov' }}
              onChange={(e) => handleProviderSearch(e)}
            />
            <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
              <SearchIcon />
            </IconButton>
          </Paper>
          <Box className={styles.providers}>
            {providersList?.map((item: any, index: number) => {
              return (
                <Box
                  key={index}
                  className={cx(styles.logo, {
                    [styles.active]: item.id === selectedSubProvider,
                  })}
                  onClick={() => handleOnClickSubProvider(item.id)}
                >
                  <img src={item.image} alt="game" />
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>
      <Box className={styles.right_side}>
        <Box
          sx={{
            width: '100%',
            padding: '0px 24px 12px',
            mt: 3,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {user && (
            <Box className={styles.icon_menu}>
              <IconButton
                className={cx({
                  [styles.active]: activeIconMenu === 'resentGames',
                })}
                type="button"
                aria-label="star"
                onClick={() => changeActiveIconMenu('resentGames')}
              >
                <ScheduleIcon />
              </IconButton>
              <IconButton
                className={cx({
                  [styles.active]: activeIconMenu === 'favoriteGames',
                })}
                type="button"
                aria-label="favorite"
                onClick={() => changeActiveIconMenu('favoriteGames')}
              >
                <FavoriteIcon />
              </IconButton>
            </Box>
          )}
          <Paper
            onSubmit={handleSubmit}
            className={classes.paper}
            component="form"
            sx={{ display: 'flex', alignItems: 'center', width: '100%' }}
          >
            <InputBase
              size="small"
              fullWidth
              value={gameName ?? ''}
              sx={{ ml: 1, flex: 1 }}
              disabled={activeIconMenu === 'resentGames' ? true : false}
              onChange={(e) => handleGameSearch(e)}
              placeholder={t('searchGame')}
              inputProps={{ 'aria-label': 'search game' }}
            />
            <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
              <SearchIcon />
            </IconButton>
          </Paper>
        </Box>
        <Box
          className={cx(styles.games, {
            [styles.loader]: isLoading,
            [styles.noResults]: !activeGames,
          })}
        >
          {isLoading || isLoadingSpecialGames ? (
            <Stack sx={{ minHeight: '600px', color: 'grey.500' }} spacing={2} direction="row">
              <CircularProgress color="warning" />
            </Stack>
          ) : (
            <>
              {activeGames ? (
                activeGames?.map(({ image, id, providerId, isFavorite }: any, index: number) => {
                  return (
                    <GameCard
                      key={index}
                      imageUrl={image}
                      gameId={id}
                      className={styles.gameItem}
                      providerId={providerId}
                      isFavorite={isFavorite}
                    />
                  );
                })
              ) : (
                <Typography component={'div'} sx={{ color: 'grey.500', textAlign: 'center' }}>
                  {t('noResult')}
                </Typography>
              )}
            </>
          )}
        </Box>
        {globalTotalPages && globalTotalPages > 1 && page < globalTotalPages ? (
          <Stack className={styles.showMore} spacing={2}>
            <Button onClick={(e) => handleOnClickMore(e, globalTotalPages)}>{t('showMore')}</Button>
          </Stack>
        ) : null}
      </Box>
    </Box>
  );
};
