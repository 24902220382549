import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface SelectCategoryProps {
  onSelect?: (value: string) => void;
  initValue?: string;
  width?: number | string;
  margin?: string;
  categoryList?: any[];
  subProviderList?: any[];
}

export default function CommonSelect({
  onSelect,
  margin,
  initValue,
  width,
  categoryList,
  subProviderList,
}: SelectCategoryProps) {
  const { t } = useTranslation('common');
  const [data, setData] = useState('');

  const handleChange = (event: SelectChangeEvent) => {
    const { value } = event.target;
    setData(value);
    onSelect?.(value);
  };

  const customWidth = width !== undefined ? width : 300;
  const customMargin = margin !== undefined ? margin : '0 0 24px 0';

  useEffect(() => {
    if (initValue) setData(initValue);
  }, [initValue]);

  return (
    <Box sx={{ width: customWidth, margin: customMargin }}>
      {!subProviderList ? (
        <FormControl fullWidth size="small">
          <InputLabel id="site-name-label">{t('categoryList')}</InputLabel>
          <Select
            labelId="site-name-label"
            id="site-select"
            value={data}
            label={t('categoryList')}
            onChange={handleChange}
          >
            {categoryList?.map((el) => {
              return (
                <MenuItem value={el.id} key={el.id}>
                  {el.title}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      ) : (
        <FormControl fullWidth size="small">
          <InputLabel id="provider-name-label">{t('subProviders')}</InputLabel>
          <Select
            style={{ height: '45px' }}
            labelId="provider-name-label"
            id="provider-select"
            value={data}
            label={t('subProviders')}
            onChange={handleChange}
          >
            {subProviderList?.map((elm, i) => {
              return (
                <MenuItem value={elm.id} key={i}>
                  <img
                    src={elm.image}
                    style={{ objectFit: 'contain', width: '150px', height: '25px' }}
                  />
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}
    </Box>
  );
}
