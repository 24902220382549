import Cash from 'assets/bonuses/cash.png';
import Mail from 'assets/bonuses/mail.png';
import Promo from 'assets/bonuses/promo.png';
import Vk from 'assets/bonuses/vk.png';
import Telegram from 'assets/bonuses/telegram.png';
import Facebook from 'assets/bonuses/fb.png';


export const mocked_Bonuses = {
  ru: [
    {
      id: '1',
      title: 'Подпишись на наш телеграмм',
      description: {
        title: '',
        content: `Для Вашего удобства мы создали Телеграмм канал «@spinwager”»,
        где Вас ждёт информация о Бонусах,акциях и актуальных ссылках на сайт .
        Подпишитесь на наш Телеграмм канал и читайте новости раньше всех.`,
      },
      icon: Telegram,
    },
    {
      id: '2',
      title: 'Вконтакт',
      description: {
        title: '',
        content: `Вступайте в нашу группу в социальной сети Вконтакте вы
        получите в 100 рублей на игровой баланс. В группе разыгрывают индивидуальные «промо коды»`,
      },
      icon: Vk,
    },
    {
      id: '3',
      title: 'Бонус доступен только при подтвержденной почте',
      description: {
        title: '',
        content: `Бонус необходимо отыграть, то есть сделать общую сумму 
        ставок согласно условиям бонуса (вейджер).
       До полного отыгрывания бонусов выплаты выигрышей не производятся. 
       Выигранные при использовании бонусов деньги нельзя снять со счета, пока все бонусы не отыграны. 
       Полученные бонусы отыгрываются в порядке очереди.
       БОНУС ДОСТУПЕН ТЕМ ИГРОКАМ КОТОРЫЕ АКТИВНО ИГРАЛИ В ТЕЧЕНИЕ МЕСЯЦА`,
      },
      icon: Mail,
    },
    {
      id: '4',
      title: 'ПРОМОКОД ДЛЯ НОВЫХ КЛИЕНТОВ',
      description: {
        title: '',
        content: `Бездепозитный бонус в казино Spinwager за регистрацию можно получить 
        только по нашему промокоду, который выдается только по ПРИГЛОШЕНИЮ .
        Все предлагаемые промокоды от казино Spinwager направлены на то, чтобы игрок проводил 
        как можно больше времени на площадке.
        Долгое отсутствие на сайте казино может привести к пропуску периода действия промокода, 
        и к потере активированного им бонуса.
        
        Промокод можно активировать сразу при регистрации, либо потом в разделе «Депозит».`,
      },
      icon: Promo,
    },
    {
      id: '5',
      title: 'КЭШБЭК',
      description: {
        title: '',
        content: `Каждый день в 09:00 по московскому времени сайт Казино начисляет Кэшбэк в 
        размере 5% от суммы всех Ваших депозитов, совершенных в течении предыдущего дня .
        Дополнительный кэшбэк от некоторых провайдеров составляет 3% - 5% в зависимости от уровня клиента.
        Кэшбэк 5% возврата, получают все игроки, чья активность на сайте была в течении 24 часов.
        В случае проигрыша бонусных средств кэшбэк также начисляется.
        Игрок имеет право на снятие кэшбэка без удержания комиссии. 
        Начисление кэшбэка происходит автоматически в зависимости от уровня клиента`,
      },
      icon: Cash,
    },
    {
        id: '6',
        title: 'СТАНЬ НАШИМ ДРУГОМ НА FACEBOOK',
        description: {
          title: '',
          content: `Получайте 100 рублей каждый месяц! 
          Бонус доступен для тех игроков у кого статус Бронзовый и выше !
           Напишите менеджеру на FaceBook , и нажмите кнопку 'Добавить в Друзья'. 
           Менеджер подтвердит запрос и пополнит ваш игровой счёт 100 рублей каждый месяц!
           Этот вид бонуса недоступен для уровня Основной!
          БОНУС ДОСТУПЕН ТЕМ ИГРОКАМ КОТОРЫЕ АКТИВНО ИГРАЛИ В ТЕЧЕНИЕ МЕСЯЦА`,
        },
        icon: Facebook,
      },
  ],
  en: [
    {
      id: '1',
      title: 'Subscribe to our telegram',
      description: {
        title: '',
        content: `For your convenience, we have created a Telegram channel “@spinwager”, 
        where you will find information about Bonuses, promotions and current links to the website.
        Subscribe to our Telegram channel and read the news before anyone else.`,
      },
      icon: Telegram,
    },
    {
      id: '2',
      title: 'Vkontakt',
      description: {
        title: '',
        content: `Join our group on the social network “VKontakte” receive 100 rubles in 
        your game balance. Individual “promo codes” are being drawn in the group
        THE BONUS IS AVAILABLE TO THOSE PLAYERS WHO ACTIVELY PLAYED DURING THE MONTH`,
      },
      icon: Vk,
    },
    {
      id: '3',
      title: 'BONUS AVAILABLE ONLY WITH CONFIRMED EMAIL',
      description: {
        title: '',
        content: `The bonus must be wagered, that is, the total amount of bets must be made according to the terms of the bonus.
        No winnings will be paid out until bonuses are fully wagered. 
        Money won by using bonuses cannot be withdrawn from the account until all bonuses have been wagered.
       Received bonuses are wagered in order of priority.
       THE BONUS IS AVAILABLE TO THOSE PLAYERS WHO ACTIVELY PLAYED DURING THE MONTH`,
      },
      icon: Mail,
    },
    {
      id: '4',
      title: 'PROMO CODE FOR NEW CLIENTS',
      description: {
        title: '',
        content: `A no deposit bonus at the casino Spinwager for registration can only be received using our promo code, which is issued only by INVITATION.
        All offered promo codes from casino Spinwager are aimed at ensuring that the player spends as much time as possible on the site.
        A long absence from the casino website can lead to missing the period of validity of the promotional code and to the loss of the bonus activated by it.
        
        The promotional code can be activated immediately upon registration, or later in the “Deposit” section.`,
      },
      icon: Promo,
    },
    {
      id: '5',
      title: 'CASHBACK',
      description: {
        title: '',
        content: `Every day at 09:00 (Moscow time), the Casino website credits Cashback in 
        the amount of 5% of the amount of all your deposits made during the previous day.
        Additional cashback from some providers is 3% - 5% depending on the client level.
        5% cashback return is received by all players whose activity on the website was within 24 hours.
        In case of loss of bonus funds, cashback is also awarded.
        The player has the right to withdraw cashback without deducting a commission.
        Cashback is accrued automatically depending on the client’s level.`,
      },
      icon: Cash,
    },
    {
        id: '6',
        title: 'FACEBOOK',
        description: {
          title: '',
          content: `Get 100 rubles every month!
          The bonus is available for those players who have Bronze status and higher!
          Text a message to manager on FaceBook and click 'Add to Friends' button. 
          The manager will confirm the request and top up your gaming account with 100 rubles every month!
          This type of bonus is not available for the Basic level!
          THE BONUS IS AVAILABLE TO THOSE PLAYERS WHO ACTIVELY PLAYED DURING THE MONTH.`,
        },
        icon: Facebook,
      },
  ],
} as { [x: string]: any };
