import { Box, Button, Typography } from '@mui/material';
import { useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import eiUser from 'assets/icons/eiUser.svg';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';
import EmojiEventsRoundedIcon from '@mui/icons-material/EmojiEventsRounded';
import RedeemIcon from '@mui/icons-material/Redeem';
import React from 'react';
import Level from 'components/level/level';
import styles from './userLevels.module.scss';

/**TODO: translate all words in this file */

export const renderItem = (item: any, index: number, disabled?: boolean) => {
  return (
    <Box className={styles.benefit} key={index}>
      <Box className={styles.benefit_img} style={{ backgroundImage: `url(${item.image})` }} />
      <Box className={styles.benefit_name}>{item.name}</Box>
      <Box className={styles.benefit_title}>
        <Box className={styles.item}>
          <Box className={styles.item_box}>
            <Typography component="p" className={styles.title}>
              {item.minDepositBorder}
            </Typography>
            <Typography component="p">Мин. депозит</Typography>
          </Box>
        </Box>
        <Box className={styles.item}>
          <Box className={styles.item_box}>
            <Typography component="p" className={styles.title}>
              Нет лимита
            </Typography>
            <Typography component="p">Вывод средств</Typography>
          </Box>
        </Box>
        <Box className={styles.item}>
          <Box className={styles.item_box}>
            <Typography component="p" className={styles.title}>
              {`${item?.cashbackPercent}%`}
            </Typography>
            <Typography component="p">Кэшбэк</Typography>
          </Box>
        </Box>
      </Box>
      <Box className={styles.benefit_content}>
        <Box className={cx(styles.item, { [styles.disabled]: disabled })}>
          <Box className={styles.img}>
            <img src={eiUser} />
          </Box>
          <Typography component="p">Поддержка 24/7</Typography>

          <Box className={styles.icon}>{React.createElement(CheckIcon)}</Box>
        </Box>
        {/* TODO: get flag from BE, instead of using index !== 3  */}
        <Box className={cx(styles.item, { [styles.disabled]: index !== 3 })}>
          <Box className={styles.img}>{React.createElement(EmojiEventsRoundedIcon)}</Box>
          <Typography component="p">Закрытые лотереи, турниры</Typography>

          <Box className={styles.icon}>{React.createElement(CheckIcon)}</Box>
        </Box>
        <Box className={cx(styles.item, { [styles.disabled]: index !== 4 })}>
          <Box className={styles.img}>{React.createElement(RedeemIcon)}</Box>
          <Typography component="p">Подарки на праздники, день рождения</Typography>

          <Box className={styles.icon}>{React.createElement(CheckIcon)}</Box>
        </Box>

        <Box className={cx(styles.item, { [styles.disabled]: index !== 4 })}>
          <Box className={styles.img}>{React.createElement(StarBorderRoundedIcon)}</Box>
          <Typography component="p">VIP менеджер</Typography>

          <Box className={styles.icon}>{React.createElement(CheckIcon)}</Box>
        </Box>
      </Box>
    </Box>
  );
};

function UserLevels() {
  const { t } = useTranslation('levels');
  const [showAll, setShowAll] = useState(false);
  const { levelGroups } = useSelector<any>((state) => state.siteConfigs) as { [x: string]: any };

  const handleOnClick = () => {
    setShowAll(true);
  };

  return (
    <Box className={styles.main_area}>
      <Box className={styles.main_title}>
        {t('userLevels')}
        <Box>{React.createElement(RocketLaunchIcon)}</Box>
      </Box>
      <Box className={styles.levels_block}>
        <Level variant="determinate" className={styles.bg_levels} />
        <Box className={styles.benefit}>
          <Box sx={{ m: 1, textAlign: 'center' }}>Преимущество вашего уровня</Box>
          <Box className={styles.benefit_title}>
            <Box className={styles.item}>
              <Box className={styles.item_box}>
                <Typography component="p" className={styles.title}>
                  {levelGroups[0].minDepositBorder}
                </Typography>
                <Typography component="p"> Мин. депозит</Typography>
              </Box>
              <Box className={styles.vertical_line} />
            </Box>
            <Box className={styles.item}>
              <Box className={styles.item_box}>
                <Typography component="p" className={styles.title}>
                  Нет лимита
                </Typography>
                <Typography component="p">Вывод средств</Typography>
              </Box>
              <Box className={styles.vertical_line} />
            </Box>
            <Box className={styles.item}>
              <Box className={styles.item_box}>
                <Typography component="p" className={styles.title}>
                  {`${levelGroups[0]?.cashbackPercent}%`}
                </Typography>
                <Typography component="p">Кэшбэк</Typography>
              </Box>
            </Box>
          </Box>
          <Box className={styles.benefit_content}>
            <Box className={cx(styles.item, { [styles.disabled]: false })}>
              <Box className={styles.img}>
                <img src={eiUser} />
              </Box>
              <Typography component="p">Поддержка 24/7</Typography>

              <Box className={styles.icon}>{React.createElement(CheckIcon)}</Box>
            </Box>
            <Box className={cx(styles.item, { [styles.disabled]: true })}>
              <Box className={styles.img}>{React.createElement(EmojiEventsRoundedIcon)}</Box>
              <Typography component="p">Закрытые лотереи, турниры</Typography>

              <Box className={styles.icon}>{React.createElement(CheckIcon)}</Box>
            </Box>
            <Box className={cx(styles.item, { [styles.disabled]: true })}>
              <Box className={styles.img}>{React.createElement(RedeemIcon)}</Box>
              <Typography component="p">Подарки на праздники, день рождения</Typography>

              <Box className={styles.icon}>{React.createElement(CheckIcon)}</Box>
            </Box>
            <Box className={cx(styles.item, { [styles.disabled]: true })}>
              <Box className={styles.img}>{React.createElement(StarBorderRoundedIcon)}</Box>
              <Typography component="p">VIP менеджер</Typography>

              <Box className={styles.icon}>{React.createElement(CheckIcon)}</Box>
            </Box>
          </Box>
        </Box>
      </Box>
      {showAll ? (
        <Box className={styles.all_levels}>
          <Box className={styles.title}>
            Все уровни
            <Typography component="p">
              Проходи через все уровни , получай призы и увеличивай свои доходы.
            </Typography>
          </Box>
          <Box className={styles.content}>
            {levelGroups.map((el: any, index: number) => {
              return renderItem(el, index);
            })}
          </Box>
        </Box>
      ) : (
        <Box>
          <Box className={styles.bonus}></Box>
          <Button sx={{ mt: 2 }} fullWidth variant="contained" onClick={handleOnClick}>
            Показать все уровни
          </Button>
        </Box>
      )}
    </Box>
  );
}

export default UserLevels;
