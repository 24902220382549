import LobbyIcon from 'assets/icons/menuIcons/lobby.svg';
import SlotsIcon from 'assets/icons/menuIcons/slots.svg';
import StatisticsIcon from 'assets/icons/menuIcons/statistics.svg';
import X_00 from 'assets/icons/menuIcons/x00.svg';
import X_0 from 'assets/icons/menuIcons/x0.svg';

import BalanceIcon from 'assets/icons/menuIcons/balance.svg';
import X_icon from 'assets/icons/menuIcons/x.svg';

import WheelIcon from 'assets/icons/menuIcons/wheel.svg';
import LiveGamesIcon from 'assets/icons/menuIcons/live.png';
import VipClubIcon from 'assets/icons/menuIcons/vipClub.svg';
import X_000 from 'assets/icons/menuIcons/x000.svg';
import NewGameIcon from 'assets/icons/newGames.svg';
import TopGameIcon from 'assets/icons/top.svg';

export const Lobby = LobbyIcon;
export const Slots = SlotsIcon;
export const Statistics = StatisticsIcon;
export const Balance = BalanceIcon;
export const Games = X_icon;
export const Bonuses = X_00;
export const Wheel = WheelIcon;
export const Deposit = X_0;
export const LiveGames = LiveGamesIcon;
export const VipClub = VipClubIcon;
export const Favorites = X_000;
export const NewGame = NewGameIcon;
export const TopGame = TopGameIcon;
