import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useState } from 'react';
import cx from 'classnames';
import { playAudio } from 'components/wheel/wheel/wheel';
import { useStorage } from 'hooks/authHooks/useStorage';
import { useDispatch, useSelector } from 'react-redux';
import { IUserBalance } from 'types/user';
import { updateUserBalance } from 'redux/actions/userDataAction';
import animationData from 'assets/wheel/animCoin.json';
import Lottie from 'react-lottie';
import { Typography } from '@mui/material';
import { WHEEL_TYPE } from 'components/wheel/wheel';
import styles from './dialog.module.scss';

interface AlertDialogProps {
  type: string;
  title: string;
  context: string;
  btnText: string;
  isOpen: boolean;
  bonus?: string;
  closeDialog?: (state: boolean, type?: string) => void;
}

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
};

export default function AlertDialog({
  isOpen,
  closeDialog,
  title,
  context,
  btnText,
  type,
  bonus,
}: AlertDialogProps) {
  const { updateData } = useStorage();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(isOpen);
  const [isWin, setIsWin] = useState(false);

  const pathBonusaudio = './bonus.mp3';
  const bonusSound = playAudio(pathBonusaudio);
  const userData = useSelector<any>((state) => state.userData) as any;

  const handleClose = () => {
    if (type === WHEEL_TYPE.SPINNED && bonus) {
      const userCurrentBonus = userData?.balance?.filter((el: IUserBalance) => +el.id === 1)?.[0];
      const userCurrentBonusAmount = userCurrentBonus?.amount;
      userCurrentBonus['amount'] = +userCurrentBonusAmount + +bonus;

      dispatch(updateUserBalance(userData.balance));
      updateData({ balance: userData.balance });
    }
    closeDialog?.(false, WHEEL_TYPE.WAITING);
  };

  useEffect(() => {
    setIsWin(type === WHEEL_TYPE.SPINNED);
    if (type === WHEEL_TYPE.SPINNED) {
      bonusSound.play();
    }
    setOpen(isOpen);
    return () => {
      bonusSound.pause();
    };
  }, [isOpen, type]);

  return (
    <Dialog
      className={cx(styles.dialog, { [styles.isWin]: isWin })}
      sx={{ p: 20 }}
      open={open}
      onClose={handleClose}
    >
      <DialogTitle className={cx({ [styles.win_title]: isWin })}>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText className={cx({ [styles.win_context]: isWin })}>
          <Typography> {context}</Typography>
          {isWin ? <Lottie options={defaultOptions} height={145} width={135} /> : null}
          <Typography className={cx({ [styles.bonus]: isWin })}>
            {bonus}
            {isWin ? <Typography component={'span'}> {userData?.currency?.code}</Typography> : null}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions className={styles.btn}>
        <Button onClick={handleClose}>{btnText}</Button>
      </DialogActions>
    </Dialog>
  );
}
