import http2 from 'http2-common';
import http from 'http-common';

class UserDataService {
  getUserData = (headers) => {
    return http2.get(`/users/one`, { headers });
  };

  getUsersFavoriteGames = (headers, query) => {
    return http2.get(`/games/favorite?${query}`, { headers });
  };

  getUsersRecentGames = (headers, forModal) => {
    return http2.get(`/${forModal ? 'users' : 'games'}/last-played`, { headers });
  };
  
  getUsersLevelGroup = (headers) => {
    return http2.get(`/users/level-group`, { headers });
  };

  userNotificationsSeen = (headers, body) => {
    return http2.put('/users/notifications', body, { headers })
  }

  getUserNotifications = (headers, query = '') => {
    return http2.get(`/users/notifications${query}`, { headers })
  }

  sendVerificationImages = (headers, body) => {
    return http2.post('/users/verification', body, { headers });
  };

  updateUserData = (headers, userId, body) => {
    return http.put(`/users/${userId}`, body, { headers });
  };

  trasferUserBalance = (headers, body) => {
    return http2.post('/finances/transfer', body, { headers });
  };

  getUserWheelBonus = (headers) => {
    return http2.post('/games/wheel', {}, { headers });
  };

  getUserWheelDiff = (headers) => {
    return http2.get('/games/wheel', { headers });
  };

  getUserBonusesData = (headers) => {
    return http2.get(`/users/bonuses/all`, { headers })
  }
}

const UserService = new UserDataService();

export default UserService;
