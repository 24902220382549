// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Navigation, Pagination, Autoplay, A11y } from 'swiper/modules';
import GameCard from 'components/gameCard/gameCard';
import { Swiper, SwiperSlide } from 'swiper/react';
import provider from 'assets/providers/providerItem.png';
import { ICarouselItems } from 'types/game';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { useNavigate } from 'react-router-dom';
import { routing } from 'constants/routing';
import styles from './providersCarousel.module.scss';

interface ProvidersCarouselProps {
  width?: stirng;
  withoutGames?: boolean;
  carouselItems: ICarouselItems[];
}

export function ProvidersCarousel({ width, carouselItems, withoutGames }: ProvidersCarouselProps) {
  const navigate = useNavigate();

  const handleClick = (id: string, imageUrl: string) => {
    navigate(`${routing.provider}/${id}`, {
      state: {
        imageUrl: imageUrl,
      },
    });
  };

  return (
    <Swiper
      modules={[Autoplay, Navigation, Pagination, A11y]}
      spaceBetween={25}
      slidesPerView={'auto'}
      scrollbar={{ draggable: true }}
      loop={true}
      className={styles.sliderWrapper}
      style={{ width: width ? width : withoutGames ? '100%' : '' }}
    >
      {carouselItems?.map(({ image, id, providerId, games, name}, index) => {
        return (
          <SwiperSlide key={index}>
            {!withoutGames ? (
              <GameCard
                imageUrl={provider}
                gameId={id}
                providerId={providerId}
                withoutAction
                logo={image}
                smallImages={[...games]}
              />
            ) : (
              <img
                src={image}
                style={{
                  objectFit: 'contain',
                  marginTop: '45px',
                  maxWidth: '100px',
                  cursor: 'pointer',
                }}
                height={'40px'}
                onClick={() => handleClick(id, image)}
                alt={name}
              />
            )}
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
}
